import React, { useEffect, useState } from "react";
import { MessageBody, MessageHeader } from "./Message";
import "./MessageOptionList.scss";
import { ReactComponent as EditIcon } from "../../assets/svg/message-edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/message-remove.svg";
import { ReactComponent as ReplyIcon } from "../../assets/svg/message-reply.svg";
import { ReactComponent as CopyIcon } from "../../assets/svg/message-copy.svg";
import initMatrix from "../../../client/initMatrix";
import data from "@emoji-mart/data";
import { init } from "emoji-mart";

init({ data });

const EmojiIcon = ({ native, label }) => (
  <span role="img" aria-label={label} className="emoji-icon">
    {native}
  </span>
);

const MessageOptionItem = ({ label, Icon, onClick }) => {
  const isDelete = label.toLowerCase() === "delete";

  return (
    <div
      onClick={onClick}
      className={`message-option-item ${isDelete ? "delete" : ""}`}
    >
      <span className="message-option-item-text">{label}</span>
      <Icon className="message-option-item-svglogo" />
    </div>
  );
};

const ReactionOptionItem = ({ Icon, onClick, isActive }) => (
  <div
    onClick={onClick}
    className={`reaction-option-item ${isActive ? "active-reaction" : ""}`}
  >
    {Icon}
  </div>
);

const MessageOptionList = ({
  onOptionSelect,
  onReactionsSelect,
  body,
  timestamp,
  fullTime,
  isCurrentMessenger,
  isBodyOnly,
  isDM,
  userId,
  mEvent,
  messageOptions,
  roomId,
  reactions,
  room,
  mx,
}) => {
  const [canDeleteMessages, setCanDeleteMessages] = useState(false);
  const currentUserId = mx.getUserId();

  useEffect(() => {
    const myPowerLevel = room.getMember(mx.getUserId())?.powerLevel || 0;
    const pLEvent = room.currentState.getStateEvents("m.room.power_levels")[0];
    const permissions = pLEvent.getContent();
    const requiredPowerLevel = permissions.redact;
    setCanDeleteMessages(myPowerLevel >= requiredPowerLevel);
  }, [room, userId]);

  const handleOptionClick = (option) => {
    if (onOptionSelect) {
      const normalizedBody =
        typeof body === "string" ? body.replace(/<br\s*\/?>/gi, "\n") : body;

      onOptionSelect({
        option,
        mEvent,
        userId,
        body: normalizedBody,
      });
    }
  };

  const handleReactionClick = (unicode) => {
    if (onReactionsSelect) {
      onReactionsSelect({ reaction: unicode });
    }
  };

  const userReactions = new Set(
    reactions
      .filter(
        (reaction) =>
          reaction.getSender() === currentUserId &&
          reaction.getContent &&
          reaction.getContent()["m.relates_to"]
      )
      .map((reaction) => reaction.getContent()["m.relates_to"].key)
  );

  const reactionOptions = [
    { unicode: "U+2764", icon: <EmojiIcon native="❤️" label="Love" /> },
    { unicode: "U+1F44D", icon: <EmojiIcon native="👍" label="Thumbs Up" /> },
    { unicode: "U+1F602", icon: <EmojiIcon native="😂" label="Laugh" /> },
    { unicode: "U+1FAE8", icon: <EmojiIcon native="🫨" label="Shaking Face" /> },
    { unicode: "U+1F622", icon: <EmojiIcon native="😢" label="Sad" /> },
    { unicode: "U+1F30A", icon: <EmojiIcon native="🌊" label="Ocean Wave" /> },
  ];

  const options = [
    { label: "Reply", value: "Reply", icon: ReplyIcon },
    {
      label: "Edit",
      value: "Edit",
      icon: EditIcon,
      condition: isCurrentMessenger && typeof body === "string",
    },
    {
      label: "Copy",
      value: "Copy",
      icon: CopyIcon,
      condition: typeof body === "string",
    },
    {
      label: "Delete",
      value: "Delete",
      icon: DeleteIcon,
      condition: isCurrentMessenger || canDeleteMessages,
    },
  ];

  return (
    <div className="message-option-list">
      <div className="reaction-options-container">
        {reactionOptions.map((reaction, index) => (
          <ReactionOptionItem
            key={index}
            Icon={reaction.icon}
            onClick={() => handleReactionClick(reaction.unicode)}
            isActive={userReactions.has(reaction.unicode)}
          />
        ))}
      </div>

      <div
        className={`message-main-container ${
          isCurrentMessenger ? "current-messenger" : ""
        } ${messageOptions ? "message-options-active" : ""} no-touch`}
      >
        {!isCurrentMessenger && !isBodyOnly && !isDM && (
          <MessageHeader userId={userId} mx={mx} />
        )}
        <MessageBody
          body={body}
          timestamp={timestamp}
          fullTime={fullTime}
          mEvent={mEvent}
          isCurrentMessenger={isCurrentMessenger}
        />
      </div>
      <div className="message-options-container">
        {options
          .filter((option) => option.condition !== false)
          .map((option) => (
            <MessageOptionItem
              key={option.value}
              label={option.label}
              Icon={option.icon}
              onClick={() => handleOptionClick(option.value)}
            />
          ))}
      </div>
    </div>
  );
};

export default MessageOptionList;
