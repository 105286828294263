import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./RoomSearch.scss";
import initMatrix from "../../../client/initMatrix";
import CustomInput from "../../atoms/input/CustomInput";
import { Message } from "../message/Message";
import { useStore } from "../../hooks/useStore";
import { useMessagingContext } from "../../contexts/MessagingProviderContext";

const roomIdToBackup = new Map();

function RoomSearch({
  roomId,
  setNestedSearchDialog,
  setOpenRoomSettingsDialog,
}) {
  const [searchData, setSearchData] = useState(
    roomIdToBackup.get(roomId) ?? null
  );
  const [searchTerm, setSearchTerm] = useState("");
  const mountStore = useStore(roomId);
  const mx = initMatrix.matrixClient;
  const isRoomEncrypted = mx.isRoomEncrypted(roomId);
  const { setEventAction } = useMessagingContext();

  useEffect(() => {
    mountStore.setItem(true);
  }, [roomId]);

  useEffect(() => {
    if (searchData?.results?.length > 0) {
      roomIdToBackup.set(roomId, searchData);
    } else {
      roomIdToBackup.delete(roomId);
    }
  }, [searchData]);

  const searchRooms = async (query) => {
    setSearchData(null);
    if (query === "") {
      return;
    }
    const body = {
      search_categories: {
        room_events: {
          search_term: query,
          filter: {
            limit: 30,
            rooms: [roomId],
          },
          order_by: "recent",
          event_context: {
            before_limit: 0,
            after_limit: 0,
            include_profile: true,
          },
        },
      },
    };
    try {
      const res = await mx.search({ body });
      const data = mx.processRoomEventsSearch(
        {
          _query: body,
          results: [],
          highlights: [],
        },
        res
      );
      if (!mountStore.getItem()) return;
      setSearchData(data);
      if (!mountStore.getItem()) return;
    } catch (error) {
      setSearchData(null);
    }
  };

  const paginate = async () => {
    if (!searchData || !searchData.next_batch) return;
    try {
      const newSearchData = await mx.backPaginateRoomEventsSearch(searchData);
      if (!mountStore.getItem()) return;
      const uniqueEventIds = new Set(
        searchData.results.map((result) => result.context.timeline[0].getId())
      );
      const uniqueResults = [...searchData.results];
      newSearchData.results.forEach((result) => {
        const eventId = result.context.timeline[0].getId();
        if (!uniqueEventIds.has(eventId)) {
          uniqueResults.push(result);
          uniqueEventIds.add(eventId);
        }
      });
      const updatedSearchData = {
        ...searchData,
        results: uniqueResults,
        next_batch: newSearchData.next_batch,
      };
      if (
        updatedSearchData.results.length >= searchData.count ||
        !newSearchData.next_batch
      ) {
        updatedSearchData.next_batch = null;
      }
      setSearchData(updatedSearchData);
    } catch (error) {
      if (!mountStore.getItem()) return;
      setSearchData(null);
    }
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      resetSearch();
    } else {
      searchRooms(searchTerm);
    }
  }, [searchTerm]);

  const handleInputChange = (e) => {
    const term = e.target.value.trim();
    setSearchTerm(term);
  };

  const resetSearch = () => {
    setSearchData(null);
  };

  const handleRoomEventClick = (id) => {
    setNestedSearchDialog(false);
    setOpenRoomSettingsDialog(false);
    setEventAction(id, "both");
  };

  const renderTimeline = (timeline) => (
    <div key={timeline[0].getId()}>
      {timeline.map((mEvent) => {
        const id = mEvent.getId();
        return (
          <React.Fragment key={id}>
            <div
              className="room-search-search-result"
              onClick={() => handleRoomEventClick(id)}
            >
              <Message mEvent={mEvent} isBodyOnly={false} fullTime />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <div className="search-bar-search-results-container">
      <div className="search-bar-mobile-results-container">
        <div className="search-bar-mobile-results">
          <span className="search-bar-mobile-results-header">Room Search</span>
        </div>
        <CustomInput
          placeholder="Search for keywords"
          value={searchTerm}
          onChange={handleInputChange}
          autoFocus={[true, 250]}
        />
      </div>
      {searchData?.results.length > 0 && (
        <div className="general-settings-room-options">
          <span className="space-landing-space-data-title-text">
            {`${searchData.count} results for "${searchTerm}"`}
          </span>
        </div>
      )}
      {!isRoomEncrypted && searchData?.results.length === 0 && (
        <div className="general-settings-room-options">
          <span className="space-landing-space-data-title-text">
            No results found
          </span>
        </div>
      )}
      <div className="room-search-results-container">
        {searchData?.results.length > 0 && (
          <>
            {searchData.results.map((searchResult) => {
              const { timeline } = searchResult.context;
              return renderTimeline(timeline);
            })}
            {searchData?.results.length > 0 && searchData.next_batch && (
              <div
                onClick={paginate}
                className="room-search-search-container-button-load"
              >
                <div className="room-search-search-container-button-inline-load">
                  <span className="room-search-search-text">Load More</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

RoomSearch.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default RoomSearch;
