import { ethers } from "ethers";
import Quoter from "@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json";

const quoterAddress = import.meta.env.VITE_QUOTER_SEPOLIA;

function fromReadableAmount(amount, decimals) {
  return ethers.parseUnits(amount.toString(), decimals);
}

function toReadableAmount(rawAmount, decimals, inputValue) {
  const pattern = /^0\.0\d+$/;
  const formattedAmount = ethers.formatUnits(rawAmount, decimals);

  const [integerPart, decimalPart] = formattedAmount.split(".");

  if (pattern.test(inputValue.toString())) {
    const adjustedDecimal = decimalPart ? decimalPart.slice(0, 2) : "00";
    return `${integerPart}.${adjustedDecimal.padEnd(2, "0")}`;
  }

  const adjustedDecimal = decimalPart ? decimalPart.slice(0, 2) : "00";
  return `${integerPart}.${adjustedDecimal.padEnd(2, "0")}`;
}

const providerChainlink = new ethers.JsonRpcProvider(
  "https://ethereum-mainnet.core.chainstack.com/771c43c6fb704a3a285e66b0a349afa4"
);

const ethUsdPriceFeedAddress = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419";

const aggregatorV3InterfaceABI = [
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "description",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
    name: "getRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

async function getEthUsdPrice(providerChainlink) {
  const priceFeed = new ethers.Contract(
    ethUsdPriceFeedAddress,
    aggregatorV3InterfaceABI,
    providerChainlink
  );

  const roundData = await priceFeed.latestRoundData();
  const ethUsdPrice = Number(roundData.answer) / 1e8;
  return ethUsdPrice;
}

export async function calculateGasFee(
  inputValue,
  inputDecimals,
  provider,
  token0,
  token1,
  fee
) {
  const quoterContract = new ethers.Contract(
    quoterAddress,
    Quoter.abi,
    provider
  );

  const gasLimitEstimate = await provider.estimateGas({
    to: quoterAddress,
    data: quoterContract.interface.encodeFunctionData("quoteExactInputSingle", [
      token0,
      token1,
      fee,
      fromReadableAmount(inputValue, inputDecimals).toString(),
      0,
    ]),
  });

  const feeData = await provider.getFeeData();
  const gasPrice = feeData.gasPrice;
  const gasFeeWei = gasLimitEstimate * gasPrice;
  const gasFeeEther = ethers.formatEther(gasFeeWei);
  const ethUsdPrice = await getEthUsdPrice(providerChainlink);
  const gasFee = (parseFloat(gasFeeEther) * ethUsdPrice).toFixed(2);
  return {
    gasFee,
    gasFeeEther,
  };
}

export async function calculateOutputAmount(
  inputValue,
  inputDecimals,
  outputDecimals,
  provider,
  token0,
  token1,
  fee,
  tokenSymbol
) {
  const quoterContract = new ethers.Contract(
    quoterAddress,
    Quoter.abi,
    provider
  );

  const quotedAmountOut = await quoterContract.quoteExactInputSingle.staticCall(
    token0,
    token1,
    fee,
    fromReadableAmount(inputValue, inputDecimals).toString(),
    0
  );

  const outputAmount = BigInt(quotedAmountOut);
  const poolFeePercentage = fee / 1e6;
  const poolFeeRate = BigInt(fee) * 10n ** (18n - 6n);

  let inputValueUsed;

  if (tokenSymbol === "ETH") {
    inputValueUsed = inputValue;
  } else {
    const ethAmountOutConverted = parseFloat(
      ethers.formatEther(outputAmount)
    ).toFixed(2);
    inputValueUsed = ethAmountOutConverted;
  }
  const poolFeeInEther = inputValueUsed * poolFeePercentage;
  const poolFee = (outputAmount * poolFeeRate) / 10n ** 18n;
  const adjustedAmountOut = outputAmount - poolFee;
  const ethUsdPrice = await getEthUsdPrice(providerChainlink);
  const inputValueUsd = (parseFloat(inputValueUsed) * ethUsdPrice).toFixed(2);
  const poolFeeUsd = poolFeeInEther * ethUsdPrice;
  const outputValueUsd = (inputValueUsd - poolFeeUsd).toFixed(2);

  const readableAdjustedAmount = toReadableAmount(
    adjustedAmountOut,
    outputDecimals,
    inputValue
  );

  return {
    readableAdjustedAmount,
    inputValueUsd,
    outputValueUsd,
    adjustedAmountOut,
  };
}

export async function cleanAndFormatNumber(value) {
  const cleanedString = value.replace(/[^0-9.]/g, "");
  const decimalCount = (cleanedString.match(/\./g) || []).length;
  if (decimalCount > 1) {
    return "0";
  }
  if (cleanedString === "" || cleanedString === ".") {
    return "0";
  }
  const cleanedNumber = parseFloat(cleanedString);
  if (isNaN(cleanedNumber)) {
    return "0";
  }
  const [integerPart, fractionalPart] = cleanedString.split(".");

  const formattedInteger = parseInt(integerPart, 10).toLocaleString("en-US");
  return fractionalPart !== undefined
    ? `${formattedInteger}.${fractionalPart}`
    : formattedInteger;
}

export async function cleanNumber(value) {
  const cleanedString = value.replace(/[^0-9.]/g, "");
  const decimalCount = (cleanedString.match(/\./g) || []).length;
  if (decimalCount > 1 || cleanedString === "" || cleanedString === ".") {
    return "0";
  }
  const cleanedNumber = parseFloat(cleanedString);
  if (isNaN(cleanedNumber)) {
    return "0";
  }
  return cleanedString;
}
