import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import { roomIdByActivity, roomIdByAtoZ } from "../../../util/sort";
import RoomsCategory from "./RoomsCategory";
import "./Home.scss";
import { SpaceShortcut } from "./SideBar";
import ScrollView from "../../atoms/scroll/ScrollView";

function Home() {
  const { roomList, accountData } = initMatrix;
  const [spaceIds, setSpaceIds] = useState([]);
  const [roomIds, setRoomIds] = useState([]);
  const [spaceShortcut, setSpaceShortcut] = useState([
    ...accountData.spaceShortcut,
  ]);

  const updateRoomAndSpaceIds = () => {
    const orphanSpaceIds = roomList
      .getOrphanSpaces()
      .filter((id) => !accountData.spaceShortcut.has(id));
    const orphanRoomIds = roomList.getOrphanRooms();
    setSpaceIds(orphanSpaceIds.sort(roomIdByAtoZ));
    setRoomIds(orphanRoomIds.sort(roomIdByActivity));
  };

  useEffect(() => {
    roomList.on(cons.events.roomList.ROOMLIST_UPDATED, updateRoomAndSpaceIds);
    accountData.on(
      cons.events.accountData.SPACE_SHORTCUT_UPDATED,
      updateRoomAndSpaceIds
    );
    updateRoomAndSpaceIds();

    return () => {
      roomList.removeListener(
        cons.events.roomList.ROOMLIST_UPDATED,
        updateRoomAndSpaceIds
      );
      accountData.removeListener(
        cons.events.accountData.SPACE_SHORTCUT_UPDATED,
        updateRoomAndSpaceIds
      );
    };
  }, [roomList, accountData]);

  useEffect(() => {
    const handleShortcut = () =>
      setSpaceShortcut([...accountData.spaceShortcut]);
    accountData.on(
      cons.events.accountData.SPACE_SHORTCUT_UPDATED,
      handleShortcut
    );

    return () => {
      accountData.removeListener(
        cons.events.accountData.SPACE_SHORTCUT_UPDATED,
        handleShortcut
      );
    };
  }, [accountData]);

  return (
    <div className="home">
      <div className="home-pinned-space-shortcuts">
        <ScrollView horizontal invisible>
          <div
            className={`space-shortcut-container ${
              spaceShortcut.length === 0 ? "no-padding" : ""
            }`}
          >
            {spaceShortcut.map((shortcutId, index) => (
              <SpaceShortcut
                key={shortcutId}
                shortcutId={shortcutId}
                index={index}
                length={spaceShortcut.length}
              />
            ))}
          </div>
        </ScrollView>
      </div>
      <RoomsCategory name="Spaces" roomIds={spaceIds} />
      <RoomsCategory name="Rooms" roomIds={roomIds} />
    </div>
  );
}


Home.propTypes = {
  spaceId: PropTypes.string,
};

export default Home;
