import React, { useEffect, useRef, useState } from "react";
import {
  fetchInitialTimeline,
  fetchMoreEvents,
  listenToLiveEvents,
} from "./roomTimelineUtils";
import "./RoomEventsTimeline.scss";
import initMatrix from "../../../client/initMatrix";
import TimelineEventWrapper from "./TimelineEventWrapper";
import { markAsRead } from "../../../client/action/notifications";
import Loader from "../../atoms/loader/Loader";
import ScrollDown from "../../atoms/scroll-down/ScrollDown";
import { Capacitor } from "@capacitor/core";

const RoomEventsTimeline = ({
  roomId,
  room,
  scrollToBottomButtonRef,
  isKeyboardVisible,
  keyboardHeight,
  mx,
}) => {
  const [events, setEvents] = useState([]);
  const [timeline, setTimeline] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [lastReadEventId, setLastReadEventId] = useState(null);
  const [showDivider, setShowDivider] = useState(false);
  const containerRef = useRef(null);
  const [newEventCount, setNewEventCount] = useState(0);
  const processedEventIds = useRef(new Set());
  const latestEventTimestamp = useRef(0);
  const platform = Capacitor.getPlatform();
  const isIOS = platform === "ios";
  const timelineStyles = {
    flexDirection: isIOS ? "column" : "column-reverse",
    justifyContent: isIOS ? "flex-end" : "",
  };

  useEffect(() => {
    const initializeTimeline = async () => {
      try {
        const client = initMatrix.matrixClient;
        setEvents([]);
        processedEventIds.current.clear();
        const { events: initialEvents, timeline } = await fetchInitialTimeline(
          client,
          roomId
        );
        const sortedEvents = initialEvents.sort(
          (a, b) => a.getTs() - b.getTs()
        );
        sortedEvents.forEach((event) => {
          processedEventIds.current.add(event.getId());
        });
        if (sortedEvents.length > 0) {
          latestEventTimestamp.current =
            sortedEvents[sortedEvents.length - 1].getTs();
        }
        setEvents(sortedEvents);
        setTimeline(timeline);
        if (room) {
          const lastReadEventId = room.getEventReadUpTo(client.getUserId());
          setLastReadEventId(lastReadEventId);
          setShowDivider(true);
        }
      } catch (error) {
        console.error("Error initializing timeline:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeTimeline();

    return () => {
      setEvents([]);
      setTimeline(null);
      setLoading(true);
      setShowDivider(false);
    };
  }, [roomId]);

  useEffect(() => {
    if (!timeline) return;

    const client = initMatrix.matrixClient;

    const handleNewEvent = (event) => {
      const isEventFromMe = event.getSender() === client.getUserId();
      const eventType = event.getType();
      if (eventType === "m.reaction" || eventType === "m.room.redaction")
        return;
      if (!processedEventIds.current.has(event.getId())) {
        const eventTimestamp = event.getTs();
        if (eventTimestamp > latestEventTimestamp.current) {
          processedEventIds.current.add(event.getId());
          latestEventTimestamp.current = eventTimestamp;
          setEvents((prevEvents) => {
            const newEvents = [...prevEvents, event];
            return newEvents.sort((a, b) => a.getTs() - b.getTs());
          });
          if (!isEventFromMe) {
            setNewEventCount((prevCount) => prevCount + 1);
          }
          const container = containerRef.current;
          if (container) {
            const currentScrollPosition = container.scrollTop;
            setShowDivider(false);
            if (!isFetching) {
              if (isEventFromMe) {
                container.scrollTo({
                  top: container.scrollHeight + 100000,
                  behavior: "smooth",
                });
              } else {
                container.scrollTo({
                  top: currentScrollPosition + 0.00001,
                  behavior: "smooth",
                });
              }
            }
          }
        }
      }
    };
    const stopListening = listenToLiveEvents(client, roomId, handleNewEvent);
    return () => {
      stopListening();
    };
  }, [timeline, roomId, isFetching]);

  useEffect(() => {
    if (timeline && events.length > 0) {
      const lastEvent = events[events.length - 1];
      requestAnimationFrame(() => markAsRead(roomId, lastEvent.getId()));
    }
  }, [timeline, events]);

  let cooldown = false;

  const handleScroll = async (event) => {
    if (cooldown) return;

    const container = containerRef.current;
    if (!container) return;

    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const scrollEvent = scrollHeight + scrollPosition;
    const client = initMatrix.matrixClient;

    if (scrollEvent <= 6000 && events.length > 0 && !isFetching) {
      setIsFetching(true);

      try {
        if (timeline) {
          const { events: newEvents, timeline: updatedTimeline } =
            await fetchMoreEvents(client, room, events, 100, timeline);

          setEvents((prevEvents) => {
            const filteredEvents = newEvents.filter(
              (event) => !processedEventIds.current.has(event.getId())
            );

            filteredEvents.forEach((event) => {
              processedEventIds.current.add(event.getId());
              if (event.getTs() > latestEventTimestamp.current) {
                latestEventTimestamp.current = event.getTs();
              }
            });

            return [...filteredEvents, ...prevEvents];
          });

          setTimeline(updatedTimeline);
          cooldown = true;
          setTimeout(() => {
            cooldown = false;
          }, 200);
        }
      } catch (error) {
        console.error("Error fetching more events:", error);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const loadMoreEvents = async () => {
    const client = initMatrix.matrixClient;
    const { events: newEvents, timeline: updatedTimeline } =
      await fetchMoreEvents(client, room, events, 100, timeline);

    setEvents((prevEvents) => {
      const filteredEvents = newEvents.filter(
        (event) => !processedEventIds.current.has(event.getId())
      );

      filteredEvents.forEach((event) => {
        processedEventIds.current.add(event.getId());
        if (event.getTs() > latestEventTimestamp.current) {
          latestEventTimestamp.current = event.getTs();
        }
      });

      return [...prevEvents, ...filteredEvents];
    });

    setTimeline(updatedTimeline);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [events, isFetching]);

  const [showScrollDown, setShowScrollDown] = useState(false);

  const handleScrollBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
      setNewEventCount(0);
    }
  };

  useEffect(() => {
    const handleScrollPosition = () => {
      if (!containerRef.current) return;

      const scrollTop = Math.abs(containerRef.current.scrollTop);

      if (scrollTop > 1000) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }

      if (scrollTop < 100) {
        setNewEventCount(0);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScrollPosition);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener(
          "scroll",
          handleScrollPosition
        );
      }
    };
  }, [containerRef]);

  return (
    <div
      className="room-events-timeline"
      style={timelineStyles}
      ref={containerRef}
    >
      {room && loading && !timeline ? (
        <div className="room-events-timeline-loader">
          <Loader
            size="20px"
            dotSize="6px"
            color="var(--light)"
            multiplier={1.4}
          />
        </div>
      ) : (
        (isIOS ? events.slice() : events.slice().reverse()).map(
          (event, index) => (
            <TimelineEventWrapper
              key={`${event.getId()}-${index}`}
              event={event}
              lastReadEventId={lastReadEventId}
              showDivider={showDivider}
              events={events}
              containerRef={containerRef}
              room={room}
              roomId={roomId}
              loadMoreEvents={loadMoreEvents}
              isKeyboardVisible={isKeyboardVisible}
              timeline={timeline}
              mx={mx}
            />
          )
        )
      )}
      <ScrollDown
        handleScroll={handleScrollBottom}
        show={showScrollDown}
        scrollToBottomButtonRef={scrollToBottomButtonRef}
        newEventCount={newEventCount}
        keyboardHeight={keyboardHeight}
      />
    </div>
  );
};

export default RoomEventsTimeline;
