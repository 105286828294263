const INITIAL_LIMIT = 30;

export const fetchInitialTimeline = async (client, roomId) => {
  const room = client.getRoom(roomId);
  if (!room) {
    throw new Error("Room not found");
  }
  const timeline = room.getLiveTimeline();
  const events = timeline.getEvents();
  let allEvents = [...events];
  let canPaginate = room.oldState.paginationToken !== null;
  const addUniqueEvents = (newEvents, existingEvents) => {
    const uniqueEvents = newEvents.filter(
      (event) =>
        !existingEvents.some(
          (existingEvent) => existingEvent.getId() === event.getId()
        )
    );
    return [...uniqueEvents, ...existingEvents];
  };
  while (canPaginate && allEvents.length < INITIAL_LIMIT) {
    await client.scrollback(room, 50);
    const newEvents = timeline.getEvents();
    allEvents = addUniqueEvents(newEvents, allEvents);
    allEvents.sort((a, b) => a.getTs() - b.getTs());
    const createEventIndex = allEvents.findIndex(
      (event) => event.getType() === "m.room.create"
    );
    if (createEventIndex !== -1) {
      allEvents = allEvents.slice(createEventIndex);
    }
    canPaginate = room.oldState.paginationToken !== null;
  }
  return {
    timeline,
    events: allEvents,
  };
};

export const fetchMoreEvents = async (
  client,
  room,
  currentEvents,
  limit,
  timeline
) => {
  if (!room || !timeline) {
    throw new Error("Room or timeline not found");
  }
  let canPaginate = room.oldState.paginationToken !== null;
  if (!canPaginate) {
    return { events: currentEvents, timeline };
  }
  let allEvents = [...currentEvents];
  const addUniqueEvents = (newEvents, existingEvents) => {
    const uniqueEvents = newEvents.filter(
      (event) =>
        !existingEvents.some(
          (existingEvent) => existingEvent.getId() === event.getId()
        )
    );
    return [...uniqueEvents, ...existingEvents];
  };
  try {
    await client.paginateEventTimeline(timeline, {
      backwards: true,
      limit: limit,
    });
    const newEvents = timeline.getEvents();
    allEvents = addUniqueEvents(newEvents, allEvents);
    allEvents.sort((a, b) => a.getTs() - b.getTs());
    const createEventIndex = allEvents.findIndex(
      (event) => event.getType() === "m.room.create"
    );
    if (createEventIndex !== -1) {
      allEvents = allEvents.slice(createEventIndex); 
    }
    canPaginate = room.oldState.paginationToken !== null;
  } catch (error) {
    console.error("Failed to paginate:", error);
  }
  return {
    events: allEvents,
    timeline,
  };
};

export const listenToLiveEvents = (client, roomId, onEventReceived) => {
  const room = client.getRoom(roomId);
  if (!room) {
    throw new Error("Room not found");
  }

  const handleLiveEvent = (event) => {
    if (event.getRoomId() === roomId) {
      onEventReceived(event);
    }
  };

  client.on("Room.timeline", handleLiveEvent);

  return () => {
    client.removeListener("Room.timeline", handleLiveEvent);
  };
};
