import React, { useState, useEffect, useRef } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import "./MobileLanding.scss";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import { Capacitor } from "@capacitor/core";
import { Link } from "react-router-dom";
import { useAppConnection } from "../../contexts/AppConnectionContext";
import { useAccount } from "wagmi";
import CreateWallet from "../../molecules/setup-wallet/SetupWallet";
import Popover from "../../atoms/popover/Popover";
import { ethers } from "ethers";
import { decrypt } from "../../../util/encryption";
import SecureStoragePluginIOS from "../../../util/secureStoragePluginIOS";
import { Device } from "@capacitor/device";
import Loader from "../../atoms/loader/Loader";
import ReconnectWallet from "../../molecules/setup-wallet/ReconnectWallet";
import Modal from "../../atoms/modal/Modal";
import ConnectedWallets from "../../molecules/setup-wallet/ConnectedWallets";
import ImportWallet from "../../molecules/setup-wallet/ImportWallet";
import SecureStoragePluginAndroid from "../../../util/secureStoragePluginAndroid";
import { Dialog } from "@capacitor/dialog";
import ImportWalletSecretPhrase from "../../molecules/setup-wallet/ImportWalletSecretPhrase";

const MobileLanding = () => {
  const { open } = useWeb3Modal();
  const { isDisconnecting, handleWalletSetup } = useAppConnection();
  const dialogRef = useRef(null);
  const backgroundRef = useRef(null);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [createWalletDialog, setCreateWalletDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const isWeb = Capacitor.getPlatform() === "web";
  const [reconnectLoading, setReconnectLoading] = useState(false);
  const [reconnectWalletDialog, setReconnectWalletDialog] = useState(false);
  const [importWalletDialog, setImportWalletDialog] = useState(false);
  const [importWalletPrivateKeyDialog, setImportWalletPrivateKeyDialog] =
    useState(false);

  const { isConnected } = useAccount();
  const [isFaceIdAvailable, setIsFaceIdAvailable] = useState(false);
  const walletSetup = localStorage.getItem("waivlength_wallet");
  const walletSetupData = walletSetup ? JSON.parse(walletSetup) : null;
  const walletArray =
    JSON.parse(localStorage.getItem("waivlength_wallets")) || [];
  const walletsPresent = walletArray.length > 0;
  const importedWalletPresent = walletArray.some(
    (wallet) => wallet.importedWallet === true
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const toggleModal = () => {
    if (!isDisconnecting) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const showImportOptions = async () => {
    const result = await Dialog.confirm({
      title: "Import Wallet",
      message: "Choose an import method:",
      okButtonTitle: "Secret Phrase",
      cancelButtonTitle: "Private Key",
    });

    if (result.value) {
      setImportWalletDialog(true);
    } else {
      setImportWalletPrivateKeyDialog(true);
    }
  };

  const handleWalletSelection = async (wallet) => {
    setSelectedWallet(wallet);
    toggleModal();
    await handleReconnectWallet(wallet);
  };

  const handleConnectWallet = () => {
    if (!isDisconnecting && !isConnected) {
      open();
    }
  };

  const isFaceIdSupported = (model) => {
    const modelNumber = parseInt(model.replace("iPhone", "").split(",")[0], 10);
    return modelNumber >= 10;
  };

  useEffect(() => {
    const checkFaceIdAvailability = async () => {
      const info = await Device.getInfo();

      if (
        info.platform === "ios" &&
        parseInt(info.osVersion.split(".")[0], 10) >= 11 &&
        isFaceIdSupported(info.model)
      ) {
        setIsFaceIdAvailable(true);
      } else if (
        info.platform === "android" &&
        parseInt(info.osVersion, 10) >= 8
      ) {
        setIsFaceIdAvailable(true);
      }
    };

    checkFaceIdAvailability();
  }, []);

  useEffect(() => {
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;

    setIsStandalone(isInStandaloneMode());
  }, []);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleClickOutside = (event) => {
    if (backgroundRef.current && backgroundRef.current === event.target) {
      toggleDialog();
    }
  };

  useEffect(() => {
    if (isDialogOpen && !isStandalone) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isDialogOpen, isStandalone]);

  const handleCreateWallet = async () => {
    setLoading(true);
    try {
      const wallet = ethers.Wallet.createRandom();
      const privateKey = wallet.privateKey;
      const mnemonic = wallet.mnemonic.phrase;

      setWalletData({
        address: wallet.address,
        mnemonic: mnemonic,
        privateKey: privateKey,
        createdWallet: true,
        importedWalletSP: false,
        importedWalletPK: false,
      });

      setCreateWalletDialog(true);
    } catch (error) {
      console.error("Error creating wallet:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReconnectWallet = async (wallet) => {
    if (isDisconnecting) {
      return;
    }

    setReconnectLoading(true);

    try {
      if (wallet) {
        const platform = window.Capacitor.getPlatform();
        let encryptedPrivateKey;

        if (wallet.useFaceId) {
          encryptedPrivateKey = await (platform === "ios"
            ? SecureStoragePluginIOS.retrieveWithFaceId({
                key: wallet.walletAddress,
              })
            : SecureStoragePluginAndroid.retrieveWithFaceId({
                key: wallet.walletAddress,
              }));

          if (encryptedPrivateKey) {
            const password = encryptedPrivateKey.password;
            const privateKey = decrypt(encryptedPrivateKey.value, password);

            handleWalletSetup(
              wallet.walletAddress,
              privateKey,
              wallet.useFaceId,
              wallet.createdWallet,
              wallet.importedWallet
            );

            setReconnectLoading(false);
            return;
          } else {
          }
        } else {
          setReconnectWalletDialog(true);
          setReconnectLoading(false);
          return;
        }
      } else {
      }
    } catch (error) {
    } finally {
      setReconnectLoading(false);
    }
  };

  return (
    <>
      <div className="mobile-landing-container">
        <div className="mobile-landing-header">
          <WaivlengthLogo
            fill="var(--color-text-primary)"
            height={100}
            width={140}
          />
          <div className="mobile-landing-preheader">
            <span className="mobile-landing-text">waivlength</span>
          </div>
        </div>
        <div className="mobile-landing-footer">
          {!isWeb && (
            <div
              className="mobile-landing-footer-div"
              onClick={handleCreateWallet}
            >
              {loading ? (
                <Loader
                  size="20px"
                  dotSize="6px"
                  color="var(--light)"
                  multiplier={1.4}
                />
              ) : (
                <span className="mobile-landing-button-text">
                  Create Wallet
                </span>
              )}
            </div>
          )}
          {!isWeb && createWalletDialog && (
            <Popover
              direction="right"
              setOpenDialog={setCreateWalletDialog}
              headerText="Create Wallet"
            >
              <CreateWallet
                walletData={walletData}
                setOpenDialog={setCreateWalletDialog}
                isFaceIdAvailable={isFaceIdAvailable}
              />
            </Popover>
          )}
          {walletsPresent && (
            <div className="mobile-landing-footer-div" onClick={toggleModal}>
              {reconnectLoading ? (
                <Loader
                  size="28px"
                  dotSize="8px"
                  color="var(--dark)"
                  multiplier={1.6}
                />
              ) : (
                <span className="mobile-landing-button-text">Reconnect</span>
              )}
            </div>
          )}
          {walletsPresent && !isWeb && reconnectWalletDialog && (
            <Popover
              direction="right"
              setOpenDialog={setReconnectWalletDialog}
              headerText="Reconnect Wallet"
            >
              <ReconnectWallet
                walletData={selectedWallet}
                setOpenDialog={setReconnectWalletDialog}
              />
            </Popover>
          )}
          <Modal isOpen={isModalOpen} onClose={toggleModal}>
            <ConnectedWallets
              wallets={walletArray}
              onWalletSelect={handleWalletSelection}
            />
          </Modal>
          {!importedWalletPresent && (
            <div
              className="mobile-landing-footer-div"
              onClick={showImportOptions}
            >
              <span className="mobile-landing-button-text">Import Wallet</span>
            </div>
          )}
          {!importedWalletPresent && importWalletDialog && (
            <Popover
              direction="right"
              setOpenDialog={setImportWalletDialog}
              headerText="Import Wallet"
            >
              <ImportWalletSecretPhrase isFaceIdAvailable={isFaceIdAvailable} />
            </Popover>
          )}
          {!importedWalletPresent && importWalletPrivateKeyDialog && (
            <Popover
              direction="right"
              setOpenDialog={setImportWalletPrivateKeyDialog}
              headerText="Import Wallet"
            >
              <ImportWallet isFaceIdAvailable={isFaceIdAvailable} />
            </Popover>
          )}
          {isWeb && (
            <div
              className="mobile-landing-footer-div"
              onClick={handleConnectWallet}
            >
              <span className="mobile-landing-button-text">Connect Wallet</span>
            </div>
          )}

          <div className="mobile-landing-footer-text">
            <span>
              By continuing, I agree to the{" "}
              <Link to="/legal/terms-of-service" className="terms-link">
                Terms of Service
              </Link>{" "}
              and consent to the{" "}
              <Link to="/legal/privacy-policy" className="terms-link">
                Privacy Policy
              </Link>
              .
            </span>
          </div>
        </div>
      </div>
      {isWeb && !isStandalone && isDialogOpen && (
        <div
          className={`mobile-landing-dialog-background ${
            !isStandalone && isDialogOpen ? "visible" : ""
          }`}
          ref={backgroundRef}
          onClick={handleClickOutside}
        >
          <div className="mobile-landing-dialog-content" ref={dialogRef}>
            <div className="mobile-landing-dialog-logo">
              <WaivlengthLogo
                fill="var(--color-text-primary)"
                height={40}
                width={66}
              />
            </div>

            <span className="mobile-landing-dialog-content-text">
              Add to Home Screen
            </span>
            <span className="mobile-landing-dialog-content-text-secondary">
              To install the app, you need to add this website to your home
              screen
            </span>
            <span className="mobile-landing-dialog-content-text-secondary">
              In your browser menu, tap the Share icon and choose Add to Home
              Screen in the options. Then open waivlength.app on your home
              screen.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileLanding;
