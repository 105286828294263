import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import { ReactComponent as AddMessage } from "../../assets/svg/message-text.svg";
import "./RoomsCategory.scss";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import CreateRoom from "../create-room/CreateRoom";
import Popover from "../../atoms/popover/Popover";
import * as roomActions from "../../../client/action/room";
import SpaceAvatar from "../../atoms/avatar/SpaceAvatar";
import Loader from "../../atoms/loader/Loader";
import NotificationBadge from "../../atoms/badge/NotificationBadge";
import cons from "../../../client/state/cons";
import { abbreviateNumber } from "../../../util/common";
import HighlightsContainer from "../../atoms/highlights/HighlightsContainer";
import { ReactComponent as RoomGrid } from "../../assets/svg/roomgrid.svg";

function SpaceRooms({ roomIds, space, spaceId }) {
  const mx = initMatrix.matrixClient;
  const isSpace = space?.getType() === "m.space";
  const userId = mx.getUserId();
  const [openDialog, setOpenDialog] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [filteredRoomIds, setFilteredRoomIds] = useState([]);
  const [joiningRoomId, setJoiningRoomId] = useState(null);
  const { setRoomId } = useRoomSelect();

  useEffect(() => {
    const userPowerLevel = space.getMember(userId)?.powerLevel || 0;
    const requiredPowerLevel =
      space.currentState.getStateEvents("m.room.power_levels", "")?.getContent()
        ?.events?.["m.room.child"] || 50;

    setHasPermission(userPowerLevel > requiredPowerLevel);
  }, [space, userId]);

  useEffect(() => {
    if (!roomIds || roomIds.length === 0) {
      setFilteredRoomIds([]);
      return;
    }

    const fetchRoomData = async () => {
      try {
        const roomsWithMembers = await Promise.all(
          roomIds.map(async ({ roomId, content }) => {
            const { name, join_rule, tokenFundingType, proposalTitle } =
              content;

            if (tokenFundingType || proposalTitle) {
              return null;
            }

            if (join_rule === "public") {
              return { roomId, name, join_rule };
            } else if (join_rule === "invite") {
              const room = mx.getRoom(roomId);
              if (!room) {
                return null;
              }

              const members = room.getMembersWithMembership("join");
              const isMember = members.some(
                (member) => member.userId === mx.getUserId()
              );
              return isMember ? { roomId, name, join_rule } : null;
            } else {
              return null;
            }
          })
        );

        const filteredRooms = roomsWithMembers.filter((room) => room !== null);
        setFilteredRoomIds(filteredRooms);
      } catch (error) {
        console.error("Error fetching room data:", error);
      }
    };

    fetchRoomData();
  }, [roomIds, mx]);

  const handleAddSpaceOrRoomClick = () => {
    if (hasPermission) {
      setOpenDialog({ isSelectorOpen: true, isSpace });
    }
  };

  const handleRoomClick = async (roomId) => {
    const isJoined =
      initMatrix.matrixClient.getRoom(roomId)?.getMyMembership() === "join";
    if (isJoined) {
      setRoomId(roomId);
      setJoiningRoomId(null);
    } else {
      await joinRoom(roomId);
    }
  };

  const joinRoom = async (roomId) => {
    try {
      setJoiningRoomId(roomId);
      await roomActions.join(roomId, false);
      handleRoomClick(roomId);
    } catch (error) {
      throw error;
    }
  };

  const renderSelector = ({ roomId, name }) => {
    const noti = initMatrix.notifications;
    const isMuted = noti.getNotiType(roomId) === cons.notifs.MUTE;
    const isUnread = !isMuted && noti.hasNoti(roomId);
    const notificationCount = abbreviateNumber(noti.getTotalNoti(roomId));
    const isAlert = noti.getHighlightNoti(roomId) !== 0;

    return (
      <div
        key={roomId}
        onClick={() => handleRoomClick(roomId)}
        className="space-room-item"
      >
        <SpaceAvatar
          roomId={roomId}
          text={name}
          size={28}
          borderRadius={6}
          includeTransitions={false}
        />

        <span className="room-selector-text-color">{name}</span>
        {isUnread && (
          <NotificationBadge
            alert={isAlert}
            content={notificationCount !== 0 ? notificationCount : null}
          />
        )}
        {joiningRoomId === roomId && (
          <Loader
            size="22px"
            dotSize="5px"
            color="var(--light)"
            multiplier={1.2}
          />
        )}
      </div>
    );
  };

  return (
    <div className="rooms-category-header-wrapper">
      {hasPermission && (
        <div className="rooms-category-add-container-floating">
          <div
            className="rooms-category-add-wrapper-floating"
            onClick={handleAddSpaceOrRoomClick}
          >
            <AddMessage className="rooms-category-add-space-svglogo-floating" />
          </div>
        </div>
      )}
      {filteredRoomIds.map(renderSelector)}
      {filteredRoomIds.length < 1 && (
        <HighlightsContainer
          text="Room Management"
          subtext="No rooms have been created yet. Keep a close eye for new rooms!"
          Icon={RoomGrid}
          iconFill="var(--color-accent-two)"
        />
      )}
      {openDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenDialog}
          headerText="Create Room"
        >
          <CreateRoom
            setOpenDialog={setOpenDialog}
            parentId={spaceId}
            isSpace={false}
          />
        </Popover>
      )}
    </div>
  );
}

export default SpaceRooms;
