import EventEmitter from "events";
import * as sdk from "matrix-js-sdk";
import Olm from "@matrix-org/olm";
import RoomList from "./state/RoomList";
import AccountData from "./state/AccountData";
import RoomsInput from "./state/RoomsInput";
import Notifications from "./state/Notifications";
import { cryptoCallbacks } from "./state/secretStorageKeys";
import navigation from "./state/navigation";
import cons from "../client/state/cons";
import { logger } from "matrix-js-sdk/lib/logger";
import { App as CapacitorApp } from "@capacitor/app";

global.Olm = Olm;

logger.disableAll();

const getSecret = (key) => localStorage.getItem(key);

class InitMatrix extends EventEmitter {
  constructor() {
    super();
    navigation.initMatrix = this;
    this.matrixClient = null;
    this.roomList = null;
    this.accountData = null;
    this.roomsInput = null;
    this.notifications = null;
    this.isInitialized = false;
    CapacitorApp.addListener("appStateChange", (state) => {
      if (!state.isActive) {
        this.isInitialized = false;
      }
    });
  }

  async init() {
    if (this.matrixClient) {
      this.matrixClient = null;
    }
    try {
      await this.startClient();
      await this.waitForPreparedState();
      this.listenEvents();
      this.isInitialized = true;
    } catch (error) {
      this.isInitialized = false;
      throw error;
    }
  }

  async startClient() {
    const indexedDBStore = new sdk.IndexedDBStore({
      indexedDB: global.indexedDB,
      localStorage: global.localStorage,
      dbName: "web-sync-store",
    });
    await indexedDBStore.startup();

    this.matrixClient = sdk.createClient({
      baseUrl: getSecret(cons.secretKey.BASE_URL),
      accessToken: getSecret(cons.secretKey.ACCESS_TOKEN),
      userId: getSecret(cons.secretKey.USER_ID),
      store: indexedDBStore,
      cryptoStore: new sdk.IndexedDBCryptoStore(
        global.indexedDB,
        "crypto-store"
      ),
      deviceId: getSecret(cons.secretKey.DEVICE_ID),
      timelineSupport: true,
      cryptoCallbacks,
      verificationMethods: ["m.sas.v1"],
    });

    await this.matrixClient.initCrypto();
    await this.matrixClient.startClient({ lazyLoadMembers: true });
    this.matrixClient.setGlobalErrorOnUnknownDevices(false);
  }

  async waitForPreparedState() {
    return new Promise((resolve, reject) => {
      const handleSync = (state) => {
        if (state === "PREPARED") {
          this.matrixClient.off("sync", handleSync);
          this.setupState();
          this.notifications._initNoti();
          resolve();
        } else if (state === "SYNCING" && this.isInitialized) {
          this.matrixClient.off("sync", handleSync);
          resolve();
        } else if (state === "ERROR" || state === "STOPPED") {
          this.matrixClient.off("sync", handleSync);
          reject(new Error(`Matrix sync failed with state: ${state}`));
        }
      };
      this.matrixClient.on("sync", handleSync);
    });
  }

  setupState() {
    global.initMatrix = this;
    this.roomList = new RoomList(this.matrixClient);
    this.accountData = new AccountData(this.roomList);
    this.roomsInput = new RoomsInput(this.matrixClient, this.roomList);
    this.notifications = new Notifications(this.roomList);
    this.notifications._initNoti();
  }

  listenEvents() {
    this.matrixClient.on("Session.logged_out", this.logout.bind(this));
  }

  async logout() {
    if (this.matrixClient) {
      try {
        this.matrixClient.stopClient();
        await this.matrixClient.logout();
        await this.matrixClient.clearStores();
      } catch (error) {
        console.error("WAIVLENGTH - LOGOUT ERROR:", error);
      } finally {
        this.clearLocalStorage();
        this.clearCacheAndReload();
        this.matrixClient = null;
      }
    }
  }

  clearLocalStorage() {
    localStorage.removeItem("waivlength_access_token");
    localStorage.removeItem("waivlength_device_id");
    localStorage.removeItem("waivlength_user_id");
    localStorage.removeItem("waivlength_base_url");
    localStorage.removeItem("waivlength_wallet_address");

    const matrixKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith("mxjssdk_")
    );
    matrixKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  clearCacheAndReload() {
    if (this.matrixClient) {
      this.matrixClient.stopClient();
      this.matrixClient.store.deleteAllData();
    }
  }
}

const initMatrix = new InitMatrix();

export default initMatrix;
