import React, { useState } from "react";
import "./CreateProposal.scss";
import * as roomActions from "../../../client/action/room";
import { v4 as uuidv4 } from "uuid";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";
import Popover from "../../atoms/popover/Popover";
import Modal from "../../atoms/modal/Modal";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-bottom.svg";
import { ReactComponent as RemoveRow } from "../../assets/svg/minus.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import DateTime from "../../atoms/date-time/DateTime";
import { marked } from "marked";
import initMatrix from "../../../client/initMatrix";
import { useEthersContext } from "../../contexts/EthersContext";

const CreateProposal = ({ setOpenDialog, parentId, scrollToInput }) => {
  const [isCreatingProposal, setIsCreatingProposal] = useState(false);
  const [proposalTitle, setProposalTitle] = useState("");
  const [proposalDiscussionLink, setProposalDiscussionLink] = useState("");
  const [proposalDescription, setProposalDescription] = useState("");
  const [error, setError] = useState("");
  const [interError, setInterError] = useState("");
  const [finalError, setFinalError] = useState("");
  const [openIntermediateDialog, setOpenIntermediateDialog] = useState(false);
  const [openFinalStepDialog, setOpenFinalStepDialog] = useState(false);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Single choice voting");
  const [isStrategyModalOpen, setIsStrategyModalOpen] = useState(false);
  const [selectedStrategyOption, setSelectedStrategyOption] =
    useState("ERC20 Balance");
  const votingOptions = [
    "Single choice voting",
    "Weighted voting",
    "Basic voting",
  ];
  const votingStrategyOptions = ["ERC20 Balance", "ETH Balance"];
  const [options, setOptions] = useState([{ id: 1 }, { id: 2 }]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const mx = initMatrix.matrixClient;
  const userId = mx.getUserId();
  const { fetchSigner } = useEthersContext();

  const addOption = () => {
    const newOptionId = options.length + 1;
    setOptions([...options, { id: newOptionId, value: "" }]);
  };

  const removeLastOption = () => {
    setOptions(options.slice(0, -1));
  };

  const handleInputChange = (id, newValue) => {
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, value: newValue } : option
    );
    setOptions(updatedOptions);
  };

  const updatedOptions =
    selectedOption === "Basic voting"
      ? [
          { id: 1, value: "For" },
          { id: 2, value: "Against" },
          { id: 3, value: "Abstain" },
        ]
      : options || [{ id: 1 }, { id: 2 }];

  const handleSubmit = async (evt) => {
    setIsCreatingProposal(true);
    evt.preventDefault();

    const currentTime = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);

    const startTimeDifference = (start - currentTime) / (1000 * 60);
    if (startTimeDifference < 15) {
      setFinalError(
        "Start time must be at least 15 minutes from the current time."
      );
      setIsCreatingProposal(false);
      return;
    }

    const endTimeDifference = (end - start) / (1000 * 60);
    if (endTimeDifference < 30) {
      setFinalError(
        "End time must be at least 30 minutes after the start time."
      );
      setIsCreatingProposal(false);
      return;
    }

    setFinalError("");

    try {
      const { signer } = await fetchSigner();
      const provider = signer.provider;
      const blockNumber = await provider.getBlockNumber();

      const governanceData = {
        proposalTitle,
        proposalDescription,
        proposalDiscussionLink,
        selectedOption,
        selectedStrategyOption,
        options,
        startTime,
        endTime,
        userId,
        blockNumber,
      };

      await roomActions.createGovernanceRoom({
        proposalTitle,
        joinRule: "public",
        alias: uuidv4(),
        parentId,
        governanceData,
      });

      setIsCreatingProposal(false);
      setOpenDialog(false);
    } catch (e) {
      console.error("Error creating governance room:", e.message);
      setIsCreatingProposal(false);
    }
  };

  const handleReview = (evt) => {
    evt.preventDefault();
    setOpenReviewDialog(true);
  };

  const handleIntermediateContinue = (evt) => {
    evt.preventDefault();

    const hasEmptyField = updatedOptions.some((option) => !option.value.trim());
    if (hasEmptyField) {
      setInterError("All option fields must be filled.");
      return;
    }

    const optionValues = updatedOptions.map((option) => option.value.trim());
    const hasDuplicates = new Set(optionValues).size !== optionValues.length;
    if (hasDuplicates) {
      setInterError("Option fields must be unique.");
      return;
    }

    setInterError("");
    setOpenFinalStepDialog(true);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    toggleModal();
  };

  const handleSelectStrategyOption = (option) => {
    setSelectedStrategyOption(option);
    toggleStrategyModal();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleStrategyModal = () => {
    setIsStrategyModalOpen(!isStrategyModalOpen);
  };

  const handleContinue = (evt) => {
    evt.preventDefault();
    if (!proposalTitle.trim()) {
      setError("Title is a required field.");
      return;
    }
    if (!proposalDescription.trim()) {
      setError("Description is a required field.");
      return;
    }
    setOpenIntermediateDialog(true);
  };

  const VotingOptions = ({ selectedOption, onSelectOption, votingOptions }) => {
    return (
      <div className="create-proposal-voting-wrapper">
        {votingOptions.map((option) => (
          <div
            key={option}
            onClick={() => onSelectOption(option)}
            className={`create-proposal-voting-container ${
              selectedOption === option ? "active" : ""
            }`}
          >
            <span className="create-proposal-voting-container-text">
              {option}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const VotingStrategyOptions = ({
    selectedStrategyOption,
    onSelectOption,
    votingStrategyOptions,
  }) => {
    return (
      <div className="create-proposal-voting-wrapper">
        {votingStrategyOptions.map((option) => (
          <div
            key={option}
            onClick={() => onSelectOption(option)}
            className={`create-proposal-voting-container ${
              selectedStrategyOption === option ? "active" : ""
            }`}
          >
            <span className="create-proposal-voting-container-text">
              {option}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const DateTimeRangePicker = ({
    setStartTime,
    startTime,
    setEndTime,
    endTime,
  }) => {
    return (
      <div className="date-time-component-wrapper">
        <div className="spaceview-tokenomics-timer-container">
          <span className="presale-tokenomics-input-header-text">
            Start Time
          </span>
          <DateTime value={startTime} setValue={setStartTime} />
        </div>
        <div className="spaceview-tokenomics-timer-container">
          <span className="presale-tokenomics-input-header-text">End Time</span>
          <DateTime value={endTime} setValue={setEndTime} />
        </div>
      </div>
    );
  };

  const ReviewProposal = () => {
    const parsedText = marked(proposalDescription);

    const formatDateTime = (dateTimeString) => {
      const date = new Date(dateTimeString);

      const formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const formattedTime = date.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      return `${formattedDate} at ${formattedTime}`;
    };

    const readableStartTime = formatDateTime(startTime);
    const readableEndTime = formatDateTime(endTime);

    return (
      <div className="create-proposal-voting-wrapper-container">
        <span className="create-proposal-proposal-title-text">
          {proposalTitle}
        </span>
        <span
          className="markdown-text"
          dangerouslySetInnerHTML={{ __html: parsedText }}
        ></span>
        <span className="create-proposal-type-secondary-text">Voting Type</span>
        <span className="create-proposal-proposal-type-text">
          {selectedOption}
        </span>
        <span className="create-proposal-type-secondary-text">
          Voting Strategy
        </span>
        <span className="create-proposal-proposal-type-text">
          {selectedStrategyOption}
        </span>
        <span className="create-proposal-type-secondary-text">
          Voting Options
        </span>
        <div className="voting-options-container">
          {options.map((option) => (
            <div key={option.id} className="create-proposal-voting-container">
              <span className="create-proposal-voting-option-container-text">
                {option.value}
              </span>
            </div>
          ))}
        </div>
        <span className="create-proposal-type-secondary-text">Start Time</span>
        <span className="create-proposal-voting-times-container-text">
          {readableStartTime}
        </span>
        <span className="create-proposal-type-secondary-text">End Time</span>
        <span className="create-proposal-voting-times-container-text">
          {readableEndTime}
        </span>
      </div>
    );
  };

  return (
    <div className="create-proposal">
      <div className="create-proposal-content-wrapper">
        <span className="create-proposal-secondary-text">Title</span>
        <CustomInput
          placeholder="Enter Proposal Title"
          value={proposalTitle}
          onChange={(e) => {
            setProposalTitle(e.target.value);
            setError("");
          }}
          scrollToInput={scrollToInput}
        />
        <span className="create-proposal-secondary-text">Description</span>
        <CustomInput
          placeholder="Enter Proposal Description"
          value={proposalDescription}
          onChange={(e) => {
            setProposalDescription(e.target.value);
            setError("");
          }}
          resizable={true}
          maxHeight="40vh"
          minHeight="40vh"
          info={true}
          scrollToInput={scrollToInput}
        />
        <div className="create-proposal-flex-container">
          <span className="create-proposal-secondary-text">
            Discussion Link (optional)
          </span>
          <CustomInput
            placeholder="Enter Discussion Link"
            value={proposalDiscussionLink}
            onChange={(e) => {
              setProposalDiscussionLink(e.target.value);
              setError("");
            }}
            scrollToInput={scrollToInput}
          />
        </div>
        {error && <span className="create-proposal-error-text">{error}</span>}
        <div className="create-proposal-flex-container-button-wrapper">
          <div
            disabled={isCreatingProposal}
            onClick={handleContinue}
            className="create-proposal-container-button-create"
          >
            <span className="create-proposal-button-text">Continue</span>
          </div>
        </div>
      </div>
      {openIntermediateDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenIntermediateDialog}
          headerText="Voting"
        >
          <div className="create-proposal">
            <div className="create-proposal-content-wrapper-options">
              <span className="create-proposal-secondary-text">Type</span>
              <div
                className="create-proposal-type-dropdown-container"
                onClick={toggleModal}
              >
                <span className="create-proposal-type-text">
                  {selectedOption}
                </span>
                <ArrowDown
                  className={`create-proposal-arrow-back-svglogo ${
                    isModalOpen ? "rotated" : ""
                  }`}
                />
              </div>
              <span className="create-proposal-secondary-text">Strategy</span>
              <div
                className="create-proposal-type-dropdown-container"
                onClick={toggleStrategyModal}
              >
                <span className="create-proposal-type-text">
                  {selectedStrategyOption}
                </span>
                <ArrowDown
                  className={`create-proposal-arrow-back-svglogo ${
                    isStrategyModalOpen ? "rotated" : ""
                  }`}
                />
              </div>
              <span className="create-proposal-secondary-text">
                Voting Options
              </span>
              <div className="create-proposal-voting-wrapper-container">
                <div className="create-proposal-voting-wrapper">
                  {updatedOptions.map((option) => (
                    <div key={option.id} className="dynamic-voting-option">
                      {selectedOption !== "Basic voting" ? (
                        <CustomInput
                          value={option.value}
                          onChange={(e) => {
                            handleInputChange(option.id, e.target.value);
                            setInterError("");
                          }}
                          placeholder={`Option ${option.id}`}
                          scrollToInput={scrollToInput}
                        />
                      ) : (
                        <div className="create-proposal-voting-container">
                          <span className="create-proposal-voting-option-container-text">
                            {option.value}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {selectedOption !== "Basic voting" && (
                  <div className="create-proposal-voting-wrapper-buttons">
                    <div className="presale-add-button-container">
                      {updatedOptions.length < 8 && (
                        <div onClick={addOption} className="presale-button-svg">
                          <Plus className="presale-button-svg-icon" />
                        </div>
                      )}
                      {updatedOptions.length > 2 && (
                        <div
                          onClick={removeLastOption}
                          className="presale-button-svg"
                        >
                          <RemoveRow className="presale-button-svg-icon" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {interError && (
                <span className="create-proposal-error-text">{interError}</span>
              )}
              <div className="create-proposal-flex-container">
                <div
                  onClick={handleIntermediateContinue}
                  className="create-proposal-container-button-create"
                >
                  <span className="create-proposal-button-text">Continue</span>
                </div>
              </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={toggleModal}>
              <VotingOptions
                selectedOption={selectedOption}
                onSelectOption={handleSelectOption}
                votingOptions={votingOptions}
              />
            </Modal>
            <Modal isOpen={isStrategyModalOpen} onClose={toggleStrategyModal}>
              <VotingStrategyOptions
                selectedStrategyOption={selectedStrategyOption}
                onSelectOption={handleSelectStrategyOption}
                votingStrategyOptions={votingStrategyOptions}
              />
            </Modal>
            {openFinalStepDialog && (
              <Popover
                direction="right"
                setOpenDialog={setOpenFinalStepDialog}
                headerText="Review and Submit Proposal"
              >
                <div className="create-proposal">
                  <div className="create-proposal-content-wrapper">
                    <DateTimeRangePicker
                      startTime={startTime}
                      endTime={endTime}
                      setStartTime={setStartTime}
                      setEndTime={setEndTime}
                    />
                  </div>
                  {finalError && (
                    <span className="create-proposal-error-text">
                      {finalError}
                    </span>
                  )}

                  <div className="create-proposal-button-wrapper">
                    <div
                      onClick={handleReview}
                      className="create-container-button-review"
                    >
                      <span className="create-proposal-button-review-text">
                        Review Proposal
                      </span>
                    </div>
                    <div
                      disabled={isCreatingProposal}
                      onClick={handleSubmit}
                      className="create-proposal-container-button-create"
                    >
                      {!isCreatingProposal ? (
                        <span className="create-proposal-button-text">
                          Submit Proposal
                        </span>
                      ) : (
                        <Loader
                          size="24px"
                          dotSize="6px"
                          color="var(--dark)"
                          multiplier={1.4}
                        />
                      )}
                    </div>
                  </div>
                  {openReviewDialog && (
                    <Popover
                      direction="right"
                      setOpenDialog={setOpenReviewDialog}
                      headerText="Proposal Review"
                    >
                      <ReviewProposal />
                    </Popover>
                  )}
                </div>
              </Popover>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default CreateProposal;
