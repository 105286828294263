import React, { useState } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import * as roomActions from "../../../client/action/room";
import {
  isRoomMuted,
  markAsRead,
  toggleMute,
} from "../../../client/action/notifications";
import {
  createSpaceShortcut,
  deleteSpaceShortcut,
} from "../../../client/action/accountData";
import { ReactComponent as MarkRead } from "../../assets/svg/fillmarkread.svg";
import { ReactComponent as AddMember } from "../../assets/svg/filladduser.svg";
import { ReactComponent as Pin } from "../../assets/svg/pin.svg";
import { ReactComponent as Mute } from "../../assets/svg/fillmute.svg";
import { ReactComponent as Unmute } from "../../assets/svg/fillunmute.svg";
import InviteUser from "../../organisms/invite-user/InviteUser";
import "./RoomOptions.scss";
import { Capacitor } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import SettingButton from "../../atoms/button/SettingsButton";
import Popover from "../../atoms/popover/Popover";

function RoomOptions({ roomId, afterOptionSelect }) {
  const mx = initMatrix.matrixClient;
  const { roomList } = initMatrix;
  const room = mx.getRoom(roomId);
  const canInvite = room?.canInvite(mx.getUserId());
  const isPinned = initMatrix.accountData.spaceShortcut.has(roomId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isDM = initMatrix.roomList.directs.has(roomId);
  const isSpace = room.getType() === "m.space";
  const [isMuted, setIsMuted] = useState(isRoomMuted(roomId));

  const handleToggleMute = async () => {
    await toggleMute(roomId);
    setIsMuted(isRoomMuted(roomId));
    afterOptionSelect();
  };

  const handleConfirm = async () => {
    const roomType = room.getType();
    let message, title;

    if (isDM) {
      message = `Are you sure that you want to leave the direct message with ${room.name}?`;
      title = "Leave Direct Message";
    } else if (roomType === "m.space") {
      message = `Are you sure that you want to leave ${room.name} space?`;
      title = "Leave Space";
    } else {
      message = `Are you sure that you want to leave ${room.name}?`;
      title = "Leave Room";
    }

    if (Capacitor.isNativePlatform()) {
      const { value } = await Dialog.confirm({
        title,
        message,
      });

      if (value) {
        roomActions.leave(roomId);
        afterOptionSelect();
      }
    } else {
      const confirmed = window.confirm(message);
      if (confirmed) {
        roomActions.leave(roomId);
        afterOptionSelect();
      }
    }
  };

  const handleMarkAsRead = () => {
    markAsRead(roomId);
    const spaceChildren = roomList.getCategorizedSpaces([roomId]);
    spaceChildren?.forEach((childIds) => {
      childIds?.forEach((childId) => {
        markAsRead(childId);
      });
    });
    afterOptionSelect();
  };

  const handlePinClick = () => {
    if (isPinned) deleteSpaceShortcut(roomId);
    else createSpaceShortcut(roomId);
    afterOptionSelect();
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <div className="room-options-container">
      <span className="room-options-secondary-text">
        {room.getType() === "m.space"
          ? "Space Options"
          : isDM
          ? "Direct Message Options"
          : "Room Options"}
      </span>
      {canInvite && !isDM && (
        <SettingButton
          primaryText={"Invite"}
          Icon={() => <AddMember className="wallet-connected-svglogo-fill" />}
          onClick={handleOpenDialog}
        />
      )}
      <SettingButton
        primaryText={"Mark as read"}
        Icon={() => <MarkRead className="wallet-connected-svglogo-fill" />}
        onClick={handleMarkAsRead}
      />
      {isSpace && (
        <SettingButton
          primaryText={isPinned ? "Unpin from favourites" : "Pin to favourites"}
          Icon={() => <Pin className="wallet-connected-svglogo-fill" />}
          onClick={handlePinClick}
        />
      )}
      {!isSpace && (
        <SettingButton
          primaryText={
            isMuted ? "Unmute room notifications" : "Mute room notifications"
          }
          Icon={() =>
            isMuted ? (
              <Unmute className="wallet-connected-svglogo-fill" />
            ) : (
              <Mute className="wallet-connected-svglogo-fill" />
            )
          }
          onClick={handleToggleMute}
        />
      )}
      <div className="disconnect-button" onClick={handleConfirm}>
        <span className="disconnect-button-text">
          {isDM
            ? "Delete Chat"
            : room.getType() === "m.space"
            ? "Leave Space"
            : "Leave Room"}
        </span>
      </div>
      {isDialogOpen && (
        <Popover
          direction="right"
          setOpenDialog={setIsDialogOpen}
          headerText="Invite Users"
        >
          <InviteUser roomId={roomId} isDialogOpen={isDialogOpen} />
        </Popover>
      )}
    </div>
  );
}

RoomOptions.propTypes = {
  roomId: PropTypes.string.isRequired,
  afterOptionSelect: PropTypes.func,
};

export default RoomOptions;
