import axios from "axios";

export const isValidReferral = async (referralCode, walletAddress) => {
  try {
    const response = await axios.post(
      "https://waivlength.app/api/validate-referral",
      { referralCode, walletAddress },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.isValid;
  } catch (error) {
    return false;
  }
};
