import React from "react";
import PropTypes from "prop-types";
import NotificationBadge from "../../atoms/badge/NotificationBadge";
import { blurOnBubbling } from "../../atoms/button/script";
import "./RoomSelector.scss";
import DrawerAvatar from "../../atoms/avatar/DrawerAvatar";
import initMatrix from "../../../client/initMatrix";
import SpaceAvatar from "../../atoms/avatar/SpaceAvatar";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { twemojify } from "../../../util/twemojify";

function RoomSelectorWrapper({
  isSelected,
  isUnread,
  onClick,
  content,
  options,
  onContextMenu,
  hasParent,
}) {
  const classes = ["room-selector"];
  if (isUnread) classes.push("room-selector--unread");
  if (isSelected) classes.push("room-selector--selected");
  if (hasParent) classes.push("room-selector--no-margin");

  return (
    <div className={classes.join(" ")}>
      <div
        className={`room-selector__content ${
          hasParent ? "room-selector__content--has-parent" : ""
        }`}
        type="button"
        onClick={onClick}
        onMouseUp={(e) => blurOnBubbling(e, ".room-selector__content")}
        onContextMenu={onContextMenu}
      >
        {content}
      </div>
      <div className="room-selector__options">{options}</div>
    </div>
  );
}

function RoomSelector({
  roomId,
  iconSrc,
  isSelected,
  isUnread,
  notificationCount,
  isAlert,
  options,
  onClick,
  onContextMenu,
}) {
  const mx = initMatrix.matrixClient;
  const isDM = initMatrix.roomList.directs.has(roomId);
  const room = mx.getRoom(roomId);
  const avatarSrc = room.getAvatarUrl(mx.baseUrl, 42, 42, "crop") || null;
  const displayName = formatDisplayName(room.name);

  let userIdForAvatar = "";
  if (isDM && room.summaryHeroes && room.summaryHeroes.length > 0) {
    userIdForAvatar =
      room.summaryHeroes.find((hero) => hero !== mx.getUserId()) || "";
  }

  const isSpace = room.getType() === "m.space";
  const spaceParentEventMap = room.currentState.events.get("m.space.parent");
  const hasParent = spaceParentEventMap
    ? Array.from(spaceParentEventMap.values()).length > 0
    : false;

  const renderAvatar = () => {
    if (isDM) {
      return <UserAvatar size={28} userId={userIdForAvatar} />;
    }
    if ((!isSpace && !hasParent) || (!isSpace && hasParent)) {
      return (
        <div className="drawer-padding-left">
          <DrawerAvatar
            iconColor="var(--color-text-secondary)"
            iconSrc={iconSrc}
            size="small"
          />
        </div>
      );
    }
    if (isSpace && !hasParent) {
      return (
        <SpaceAvatar
          roomId={room.name}
          imageSrc={avatarSrc}
          text={room.name}
          size={28}
          borderRadius={6}
          includeTransitions={false}
        />
      );
    }
    return null;
  };
  return (
    <RoomSelectorWrapper
      isSelected={isSelected}
      isUnread={isUnread}
      content={
        <>
          {renderAvatar()}
          <span className="room-selector-text-color">
            {isDM ? displayName : twemojify(room.name)}
          </span>
          {isUnread && (
            <NotificationBadge
              alert={isAlert}
              content={notificationCount !== 0 ? notificationCount : null}
            />
          )}
        </>
      }
      options={options}
      onClick={onClick}
      onContextMenu={onContextMenu}
      hasParent={hasParent}
    />
  );
}

export default RoomSelector;
