import React, { useState } from "react";
import "./WalletConnect.scss";
import WalletComponent from "./WalletComponent";
import WalletConnected from "./WalletConnected";
import Popover from "../../atoms/popover/Popover";

const WalletConnect = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const closeAllDialogs = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <WalletComponent handleDialogOpen={handleDialogOpen} />
      {openDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenDialog}
          headerText="Settings"
        >
          <WalletConnected
            closeAllDialogs={closeAllDialogs}
          />
        </Popover>
      )}
    </>
  );
};

export default WalletConnect;
