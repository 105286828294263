import React, { useState } from "react";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";
import { ethers } from "ethers";
import bip39 from "bip39";
import hdkey from "hdkey";
import Popover from "../../atoms/popover/Popover";
import SetupWallet from "../../molecules/setup-wallet/SetupWallet";
import "./SetupWallet.scss";

const ImportWalletSecretPhrase = ({ isFaceIdAvailable }) => {
  const [mnemonicWords, setMnemonicWords] = useState(Array(12).fill(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [importWalletDialog, setImportWalletDialog] = useState(false);

  const handleMnemonicChange = (index, value) => {
    const updatedWords = [...mnemonicWords];
    updatedWords[index] = value;
    setMnemonicWords(updatedWords);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const mnemonic = mnemonicWords.join(" ").trim();
      const mnemonicObj = ethers.Mnemonic.fromPhrase(mnemonic);
      const seed = mnemonicObj.computeSeed();
      const hdNode = ethers.HDNodeWallet.fromSeed(seed);
      const ethWallet = hdNode.derivePath("m/44'/60'/0'/0/0");

      setWalletData({
        address: ethWallet.address,
        mnemonic: mnemonic,
        privateKey: ethWallet.privateKey,
        createdWallet: false,
        importedWalletSP: true,
        importedWalletPK: false,
      });

      setImportWalletDialog(true);
    } catch (error) {
      console.error("Error importing wallet:", error);
      setErrorMessage(
        "Invalid mnemonic phrase. Please ensure all words are correct and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <span className="header-secondary-text">Enter your Secret Phrase</span>

      <div className="mnemonic-grid">
        {mnemonicWords.map((word, index) => (
          <CustomInput
            key={index}
            placeholder={`Word ${index + 1}`}
            type="text"
            onChange={(e) => handleMnemonicChange(index, e.target.value)}
            value={word}
          />
        ))}
      </div>

      <div
        className="create-container-button-create"
        onClick={handleSubmit}
        disabled={loading}
      >
        {!loading && <span className="create-room-button-text">Submit</span>}
        {loading && (
          <Loader
            size="24px"
            dotSize="6px"
            color="var(--dark)"
            multiplier={1.4}
          />
        )}
      </div>
      {errorMessage && (
        <div className="create-room-error-text">{errorMessage}</div>
      )}
      {importWalletDialog && (
        <Popover
          direction="right"
          setOpenDialog={setImportWalletDialog}
          headerText="Import Wallet"
        >
          <SetupWallet
            walletData={walletData}
            setOpenDialog={setImportWalletDialog}
            isFaceIdAvailable={isFaceIdAvailable}
          />
        </Popover>
      )}
    </div>
  );
};

export default ImportWalletSecretPhrase;
