import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { App } from "@capacitor/app";
import { useAccount, useDisconnect } from "wagmi";
import { web3login } from "../../client/action/auth";
import cons from "../../client/state/cons";
import isValidAddress from "../../util/isValidAddress";
import initMatrix from "../../client/initMatrix";
import { isValidBlockchainAddress } from "../../util/validateBlockchainAddress";
import { totalNotifications } from "../../client/action/notifications";

const AppConnectionContext = createContext();

export const useAppConnection = () => {
  return useContext(AppConnectionContext);
};

export const AppConnectionProvider = ({ children }) => {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [isAppConnected, setIsAppConnected] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [decryptedPrivateKey, setDecryptedPrivateKey] = useState(null);
  const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
  const jwtSecret = import.meta.env.VITE_WAIVLENGTH_JWT_SECRET;
  const addressRef = useRef(address);
  const isConnectedRef = useRef(isConnected);
  const appStateTriggered = useRef(false);

  useEffect(() => {
    addressRef.current = address;
    isConnectedRef.current = isConnected;
  }, [address, isConnected]);

  const validateAndSetAddress = useCallback(
    async (walletAddress) => {
      const currentAddress = walletAddress || addressRef.current;
      const currentIsConnected = walletAddress ? true : isConnectedRef.current;

      if (!currentAddress || !currentIsConnected) {
        setIsAppLoading(false);
        setIsAppConnected(false);
        return;
      }

      const isAddressNotNullOrEmpty =
        currentAddress !== null && currentAddress !== "";
      const isAddressInList = isValidAddress(currentAddress);
      const isValidEthAddress = isValidBlockchainAddress(currentAddress);

      if (isAddressNotNullOrEmpty && isValidEthAddress && !isAddressInList) {
        disconnect();
        setIsAppConnected(false);
        setIsAppLoading(false);
        return;
      }

      if (!isAddressNotNullOrEmpty || !isValidEthAddress || !isAddressInList) {
        disconnect();
        setIsAppConnected(false);
        setIsAppLoading(false);
        return;
      }

      try {
        let accessToken = localStorage.getItem(cons.secretKey.ACCESS_TOKEN);
        let deviceId = localStorage.getItem(cons.secretKey.DEVICE_ID);
        let userId = localStorage.getItem(cons.secretKey.USER_ID);
        let storedBaseUrl = localStorage.getItem(cons.secretKey.BASE_URL);

        if (accessToken && deviceId && userId && storedBaseUrl === baseUrl) {
          await initMatrix.init({ accessToken, deviceId, userId, baseUrl });
        } else {
          setIsAppLoading(true);
          const authResult = await web3login(
            baseUrl,
            currentAddress,
            jwtSecret
          );
          await initMatrix.init(authResult);
        }

        if (initMatrix.isInitialized) {
          setIsAppConnected(true);
          setIsAppLoading(false);
        }
      } catch (error) {
        disconnect();
        setIsAppConnected(false);
        setIsAppLoading(false);
      }
    },
    [baseUrl, jwtSecret, disconnect]
  );

  const handleWalletSetup = useCallback(
    (
      walletAddress,
      privateKey,
      useFaceId,
      createdWallet,
      importedWalletSP,
      importedWalletPK
    ) => {
      localStorage.setItem("waivlength_wallet_address", walletAddress);

      let walletArray =
        JSON.parse(localStorage.getItem("waivlength_wallets")) || [];

      const walletIndex = walletArray.findIndex(
        (wallet) => wallet.walletAddress === walletAddress
      );

      const newWalletData = {
        walletAddress,
        useFaceId,
        createdWallet,
        importedWalletSP,
        importedWalletPK,
      };

      if (walletIndex >= 0) {
        walletArray[walletIndex] = {
          ...walletArray[walletIndex],
          ...newWalletData,
        };
      } else {
        walletArray.push(newWalletData);
      }

      const walletDataString = JSON.stringify(walletArray);
      localStorage.setItem("waivlength_wallets", walletDataString);
      setDecryptedPrivateKey(privateKey);
      validateAndSetAddress(walletAddress);
    },
    [validateAndSetAddress]
  );

  useEffect(() => {
    const initializeApp = async () => {
      if (!appStateTriggered.current) {
        setIsAppLoading(true);
      }

      const storedWalletAddress = localStorage.getItem(
        "waivlength_wallet_address"
      );

      if (storedWalletAddress) {
        await validateAndSetAddress(storedWalletAddress);
      } else if (isConnected && address) {
        await validateAndSetAddress();
      } else {
        setIsAppConnected(false);
        setIsAppLoading(false);
        clearLocalStorage();
      }
    };

    const handleAppStateChange = async (state) => {
      if (state.isActive) {
        appStateTriggered.current = true;
        await initializeApp();
        appStateTriggered.current = false;
      }
    };

    const appStateListener = App.addListener(
      "appStateChange",
      handleAppStateChange
    );

    initializeApp();

    return () => {
      appStateListener.remove();
    };
  }, [address, isConnected, validateAndSetAddress]);

  const clearLocalStorage = () => {
    localStorage.removeItem("waivlength_access_token");
    localStorage.removeItem("waivlength_device_id");
    localStorage.removeItem("waivlength_user_id");
    localStorage.removeItem("waivlength_base_url");
  };

  useEffect(() => {
    if (isDisconnecting) {
      const handleDisconnect = async () => {
        setIsAppConnected(false);
        await totalNotifications(true);
        setDecryptedPrivateKey(null);
        await initMatrix.logout();
        disconnect();
        setIsDisconnecting(false);
        setIsAppLoading(false);
      };

      handleDisconnect();
    }
  }, [isDisconnecting, disconnect]);

  return (
    <AppConnectionContext.Provider
      value={{
        isAppLoading,
        isAppConnected,
        isDisconnecting,
        setIsDisconnecting,
        handleWalletSetup,
        decryptedPrivateKey,
        setDecryptedPrivateKey,
      }}
    >
      {children}
    </AppConnectionContext.Provider>
  );
};
