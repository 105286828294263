import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./MessageOptions.scss";

const MessageOptions = ({ isOpen, onClose, children, touchX, touchY }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsActive(true), 10);
    } else {
      setIsActive(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className={`message-options-overlay ${isActive ? "active" : ""}`}>
      <div
        className="message-options"
        ref={optionsRef}
        style={{
          left: isActive ? "50%" : `${touchX}px`,
          top: isActive ? "50%" : `${touchY}px`,
          transform: isActive
            ? "translate(-50%, -50%) scale(1)"
            : "translate(0, 0) scale(0.6)",
          transition:
            "transform 0.3s ease-in-out, left 0.3s ease-in-out, top 0.3s ease-in-out, opacity 0.3s ease-in-out",
          opacity: isActive ? 1 : 0,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.getElementById("message-options-root")
  );
};

export default MessageOptions;
