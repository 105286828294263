import React, { useState } from "react";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";
import { ethers } from "ethers";
import Popover from "../../atoms/popover/Popover";
import SetupWallet from "../../molecules/setup-wallet/SetupWallet";
import "./SetupWallet.scss";

const ImportWallet = ({ isFaceIdAvailable }) => {
  const [privateKey, setPrivateKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [importWalletDialog, setImportWalletDialog] = useState(false);

  const handlePrivateKeyChange = (e) => {
    setPrivateKey(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const wallet = new ethers.Wallet(privateKey);

      setWalletData({
        address: wallet.address,
        privateKey: privateKey,
        createdWallet: false,
        importedWalletSP: false,
        importedWalletPK: true,
      });

      setImportWalletDialog(true);
    } catch (error) {
      console.error("Error importing wallet:", error);
      setErrorMessage("Invalid private key. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <span className="header-secondary-text">Enter private key</span>
      <CustomInput
        placeholder="Private Key"
        type="text"
        onChange={handlePrivateKeyChange}
        autoFocus={[true, 300]}
        value={privateKey}
        resizable={true}
      />

      <div
        className="create-container-button-create"
        onClick={handleSubmit}
        disabled={loading}
      >
        {!loading && <span className="create-room-button-text">Submit</span>}
        {loading && (
          <Loader
            size="24px"
            dotSize="6px"
            color="var(--dark)"
            multiplier={1.4}
          />
        )}
      </div>
      {errorMessage && (
        <div className="create-room-error-text">{errorMessage}</div>
      )}
      {importWalletDialog && (
        <Popover
          direction="right"
          setOpenDialog={setImportWalletDialog}
          headerText="Import Wallet"
        >
          <SetupWallet
            walletData={walletData}
            setOpenDialog={setImportWalletDialog}
            isFaceIdAvailable={isFaceIdAvailable}
          />
        </Popover>
      )}
    </div>
  );
};

export default ImportWallet;
