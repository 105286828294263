import React, { useEffect } from "react";
import Home from "../../organisms/navigation/Home";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { useSelectedTab } from "../../hooks/useSelectedTab";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import "./ClientContent.scss";
import DMRoom from "../../organisms/room/DMRoom";
import Directs from "../../organisms/navigation/Directs";
import NotificationsPage from "../../organisms/notifications-page/NotificationsPage";
import Discovery from "../discovery/Discovery";

export default function ClientContent({
  roomId,
  dmRoomId,
  saleRooms,
  spaces,
}) {
  const [selectedTab] = useSelectedTab();
  const [, forceUpdate] = useForceUpdate();
  const { roomList } = initMatrix;

  useEffect(() => {
    const handleUpdate = () => {
      forceUpdate();
    };
    roomList.on(cons.events.roomList.ROOMLIST_UPDATED, handleUpdate);
    return () => {
      roomList.removeListener(
        cons.events.roomList.ROOMLIST_UPDATED,
        handleUpdate
      );
    };
  }, [forceUpdate, roomList]);

  useEffect(() => {
    const updateBackgroundColor = () => {
      const roomWrapper = document.querySelector(".room__wrapper");
      const dmroomWrapper = document.querySelector(".dmroom__wrapper");
      if (
        (roomWrapper && !roomWrapper.classList.contains("room-hidden")) ||
        (dmroomWrapper && !dmroomWrapper.classList.contains("dmroom-hidden"))
      ) {
        document.body.classList.add("bg-primary");
      } else {
        document.body.classList.remove("bg-primary");
      }
    };
    updateBackgroundColor();
    window.addEventListener("resize", updateBackgroundColor);
    return () => {
      window.removeEventListener("resize", updateBackgroundColor);
    };
  }, [roomId, dmRoomId, selectedTab]);

  useEffect(() => {
    document.body.classList.add("padding-bottom-active");
    return () => {
      document.body.classList.remove("padding-bottom-active");
    };
  }, []);

  useEffect(() => {
    const applyPaddingClass = () => {
      const roomWrapper = document.querySelector(".room__wrapper");
      const dmroomWrapper = document.querySelector(".dmroom__wrapper");

      if (roomWrapper) {
        roomWrapper.classList.toggle(
          "no-padding-bottom",
          window.visualViewport.height < 500
        );
      }

      if (dmroomWrapper) {
        dmroomWrapper.classList.toggle(
          "no-padding-bottom",
          window.visualViewport.height < 500
        );
      }
    };

    window.visualViewport.addEventListener("resize", applyPaddingClass);
    applyPaddingClass();

    return () => {
      window.visualViewport.removeEventListener("resize", applyPaddingClass);
    };
  }, []);

  return (
    <div className="client-content-container">
      <div
        className={`drawer ${
          selectedTab === cons.tabs.HOME || selectedTab === cons.tabs.DIRECTS
            ? ""
            : "drawer-hidden"
        }`}
      >
        <div className="rooms-container">
          {selectedTab === cons.tabs.HOME && <Home />}
          {selectedTab === cons.tabs.DIRECTS && (
            <Directs size={roomList.directs.size} />
          )}
        </div>
      </div>
      <div
        className={`discovery-view ${
          selectedTab === cons.tabs.DISCOVERY ? "" : "other-hidden"
        }`}
      >
        <Discovery spaces={spaces} saleRooms={saleRooms} />
      </div>
      <div
        className={`notifications-view ${
          selectedTab === cons.tabs.NOTIFICATIONS ? "" : "other-hidden"
        }`}
      >
        <NotificationsPage />
      </div>
      <div
        className={`dmroom__wrapper ${
          selectedTab === cons.tabs.DIRECTS && dmRoomId ? "" : "dmroom-hidden"
        }`}
      >
        <DMRoom
          dmRoomId={dmRoomId}
        />
      </div>
    </div>
  );
}
