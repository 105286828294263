import React from "react";
import "./Navbar.scss";
import LeftSideContainer from "./LeftSideContainer";
import RightSideContainer from "./RightSideContainer";
import SearchBarMobile from "./SearchBarMobile";

function Navbar() {
  return (
    <div className="navbar-container">
      <div className="navbar-wrapper">
        <LeftSideContainer />
        <RightSideContainer />
      </div>
      <SearchBarMobile />
    </div>
  );
}

export default Navbar;
