import React from "react";
import "./RightSideContainer.scss";
import WalletConnect from "../wallet-connect/WalletConnect.jsx";

function RightSideContainer() {
  return (
    <div className="right-side-container-wrapper">
      <WalletConnect />
    </div>
  );
}

export default RightSideContainer;
