import React, { useState, useEffect } from "react";
import initMatrix from "../../../client/initMatrix";
import SpaceTokenomicsComponent from "./SpaceTokenomicsComponent";
import { handleCreateFundraiser } from "../../../client/action/handleCreateFundraiser";
import FundraiserDetailsComponent from "./FundraiserDetailsComponent";
import TimingTactComponent from "./TimingTactComponent";
import LaunchAssuranceComponent from "./LaunchAssuranceComponent";
import "./Presale.scss";
import {
  validateFundraiserDetails,
  validateSpaceTokenomics,
  validateTimingTact,
} from "../../../util/validatePresale";
import { useLoading } from "../../contexts/LoadingContext";
import { validateTransaction } from "../../../util/txnValidation";
import { useEthersContext } from "../../contexts/EthersContext";
import Popover from "../../atoms/popover/Popover";
import Password from "../../molecules/wallet-settings/Password";
import getProvider from "../../../util/getProvider";

function Presale({ spaceId, data, setOpenCreatePresaleDialog, scrollToInput }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [presaleData, setPresaleData] = useState({
    tokenFundingType: "Presale",
    tokenTokenomics: [{}],
    presaleTargetRaiseAmount: "",
    presaleFundraiserMinContribution: "",
    presaleFundraiserMaxContribution: "",
    presaleFundraiserDescription: "",
    presaleFundraiserStartTimestamp: "",
    presaleFundraiserEndTimestamp: "",
    presaleEtherscanLink: "",
    presaleContractAddress: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const { showLoading, hideLoading } = useLoading();
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(spaceId);
  const [provider, setProvider] = useState(false);

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const { provider } = await getProvider();
        setProvider(provider);
      } catch (error) {
        console.error("Error fetching provider:", error);
      }
    };

    fetchProvider();
  }, []);

  let avatarUrl = null;
  if (room && typeof room.getAvatarUrl === "function") {
    avatarUrl = room.getAvatarUrl(mx.baseUrl, 42, 42, "crop");
  }

  const { fetchSigner, handlePasswordSubmit } = useEthersContext();

  const [openDialog, setOpenDialog] = useState(false);

  const handlePasswordInput = (password) => {
    handlePasswordSubmit(password);
    setOpenDialog(false);
  };

  async function handlePresaleGeneration() {
    const { signer, address, chainId, requirePassword } = await fetchSigner();
    setErrorMessage("");

    if (requirePassword) {
      setOpenDialog(true);
      hideLoading();
      return;
    }
    const { tokenName, tokenSymbol, tokenTotalSupply, tokenContractAddress } =
      data;
    const initialPresaleData = {
      ...presaleData,
      tokenName,
      tokenSymbol,
      tokenTotalSupply,
      tokenContractAddress,
    };

    showLoading(
      [
        "Initializing presale fundraiser",
        "Providing your tokens to the presale",
        "Finalizing parameters for launch",
      ],
      `Creating new presale fundraiser for ${data.tokenName}`
    );

    const requiredBalance = "0.001";
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );
    if (txnValidationMsg) {
      setErrorMessage(txnValidationMsg);
      hideLoading();
      return;
    }

    try {
      const result = await handleCreateFundraiser(
        spaceId,
        initialPresaleData,
        signer,
        address
      );

      if (result.error) {
        console.error("Failed to create fundraiser");
        setErrorMessage(result.error);
        hideLoading();
      } else {
        setOpenCreatePresaleDialog(false);
        setPresaleData(initialPresaleData);
        hideLoading();
      }
    } catch (error) {
      hideLoading();
      setErrorMessage("Failed to create fundraiser");
    }
  }

  const handleNext = () => {
    const validationResult = validateCurrentStep();
    if (validationResult.isValid) {
      setErrorMessage("");
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      setErrorMessage(validationResult.message);
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const validateCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return validateSpaceTokenomics(presaleData.tokenTokenomics);
      case 1:
        return validateFundraiserDetails(presaleData);
      case 2:
        return validateTimingTact(presaleData);
      default:
        return { isValid: true };
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <SpaceTokenomicsComponent
            tokenTotalSupply={data.tokenTotalSupply}
            initialTokenomics={presaleData.tokenTokenomics}
            setPresaleData={setPresaleData}
            scrollToInput={scrollToInput}
          />
        );
      case 1:
        return (
          <FundraiserDetailsComponent
            setPresaleData={setPresaleData}
            presaleData={presaleData}
            scrollToInput={scrollToInput}
          />
        );
      case 2:
        return (
          <TimingTactComponent
            setPresaleData={setPresaleData}
            presaleData={presaleData}
          />
        );
      case 3:
        return (
          <LaunchAssuranceComponent presaleData={presaleData} data={data} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="presale-form">
      <form
        className="presale-form-content-wrapper"
        onSubmit={handlePresaleGeneration}
      >
        {renderStepContent()}
      </form>
      <div className="form-navigation">
        {errorMessage && (
          <div className="create-room-error-text">{errorMessage}</div>
        )}
        <div className="presale-navigation-buttons-container">
          {currentStep > 0 && (
            <div
              className="presale-navigation-buttons-container-next"
              onClick={handleBack}
            >
              <span className="presale-navigation-buttons-container-next-text">
                Back
              </span>
            </div>
          )}
          {currentStep < 3 && (
            <div
              className="presale-navigation-buttons-container-next"
              onClick={handleNext}
            >
              <span className="presale-navigation-buttons-container-next-text">
                Next
              </span>
            </div>
          )}
          {currentStep === 3 && (
            <div
              className="presale-navigation-buttons-container-next"
              onClick={handlePresaleGeneration}
            >
              <span className="presale-navigation-buttons-container-next-text">
                Submit
              </span>
            </div>
          )}
        </div>
      </div>
      {openDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenDialog}
          headerText="Enter Password"
        >
          <Password onSubmit={handlePasswordInput} />
        </Popover>
      )}
    </div>
  );
}

export default Presale;
