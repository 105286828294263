import React, { useState, useEffect, useRef } from "react";
import "./SpaceView.scss";
import initMatrix from "../../../client/initMatrix";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import { ReactComponent as Github } from "../../assets/svg/github-icon.svg";
import { ReactComponent as Twitter } from "../../assets/svg/twitter-icon.svg";
import { ReactComponent as Website } from "../../assets/svg/website-icon.svg";
import { ReactComponent as Verify } from "../../assets/svg/verify.svg";
import { ReactComponent as Settings } from "../../assets/svg/settings-thick.svg";
import { ReactComponent as ArrowBottom } from "../../assets/svg/arrow-bottom.svg";
import { getRandomGradient } from "../../../util/gradients";
import RoomSettings from "../settings/RoomSettings";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import HighlightsContainer from "../../atoms/highlights/HighlightsContainer";
import Presale from "../presale/Presale";
import Popover from "../../atoms/popover/Popover";
import SpaceRooms from "../navigation/SpaceRooms";
import TokenFundraising from "../../molecules/token-fundraising/TokenFundraising";
import RoomMembers from "../../molecules/room-members/RoomMembers";
import Token from "../../molecules/token/Token";
import { ReactComponent as Price } from "../../assets/svg/fillprice.svg";
import { formatCreationTimestamp } from "../../../util/formatCreationTimestamp";
import getProvider from "../../../util/getProvider";
import Governance from "../governance/Governance";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(utc);

function SpaceView({ spaceId, onClose, childRooms, space }) {
  const mx = initMatrix.matrixClient;
  const [openRoomSettingsDialog, setOpenRoomSettingsDialog] = useState(false);
  const [openCreatePresaleDialog, setOpenCreatePresaleDialog] = useState();
  const [nestedMembersDialog, setNestedMembersDialog] = useState(false);
  const [provider, setProvider] = useState(false);
  const memberCount = space.getJoinedMemberCount();
  const spaceViewRef = useRef(null);

  const getRoomData = (space) => {
    const nameEvent = space.currentState.getStateEvents("m.room.name", "");
    const roomName = nameEvent?.getContent()?.name || "Unnamed Room";
    const topicEvent = space.currentState.getStateEvents("m.room.topic", "");
    const roomTopic = topicEvent?.getContent()?.topic || "No topic set";
    const tokenDataEvent = space.currentState.getStateEvents(
      "custom.token_data",
      ""
    );
    const tokenData = tokenDataEvent?.getContent() || {};
    const etherscanLink = tokenData.etherscanLink || "No etherscan link";
    const tokenName = tokenData.tokenName || "No token name";
    const tokenSymbol = tokenData.tokenSymbol || "No token symbol";
    const tokenTotalSupply =
      tokenData.tokenTotalSupply || "No token total supply";
    const tokenContractAddress =
      tokenData.tokenContractAddress || "No token contract address";

    return {
      roomName,
      roomTopic,
      etherscanLink,
      tokenName,
      tokenSymbol,
      tokenTotalSupply,
      tokenContractAddress,
    };
  };

  const data = getRoomData(space);

  const handleOpenDialogWithTab = () => {
    setOpenRoomSettingsDialog(true);
  };

  const getStateEvent = (eventType) =>
    space ? space.currentState.getStateEvents(eventType, "") : null;
  const getContent = (event) => (event ? event.getContent() : {});

  let avatarUrl = null;
  if (space && typeof space.getAvatarUrl === "function") {
    avatarUrl = space.getAvatarUrl(mx.baseUrl, 100, 100, "crop");
  }

  const bannerEvent = getStateEvent("custom.space_banner");
  const bannerUrl = bannerEvent
    ? mx.mxcUrlToHttp(bannerEvent.getContent().url, 2200, 1000, "scale")
    : null;

  const socialLinks = getContent(getStateEvent("custom.social_links"));

  const [spaceActivityTabSelected, setSpaceActivityTabSelected] =
    useState("rooms");

  const tokenDataEvent = getStateEvent("custom.token_data");
  const tokenData = tokenDataEvent ? tokenDataEvent.getContent() : null;
  const myPowerLevel = space.getMember(mx.getUserId())?.powerLevel ?? 100;
  const isPowerLevelAdminOrAbove = myPowerLevel >= 100;
  const liquidity = getStateEvent("custom.liquidity");
  const liquidityData = liquidity ? liquidity.getContent() : null;
  const createRoomEvent = getStateEvent("m.room.create");
  const creationTimestamp = createRoomEvent
    ? createRoomEvent.event.origin_server_ts
    : null;

  const openLink = (url) => {
    const fullUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `http://${url}`;
    window.open(fullUrl, "_blank");
  };

  const { gradient } = getRandomGradient(spaceId);

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const { provider } = await getProvider();
        setProvider(provider);
      } catch (error) {
        console.error("Error fetching provider:", error);
      }
    };

    fetchProvider();
  }, []);

  const [bannerHeight, setBannerHeight] = useState(140);
  const [bannerBlur, setBannerBlur] = useState(0);
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const [avatarSize, setAvatarSize] = useState(60);
  const [logoBottom, setLogoBottom] = useState(-40);
  const [logoZIndex, setLogoZIndex] = useState(1);

  const handleScroll = () => {
    if (!spaceViewRef.current) return;

    const scrollTop = spaceViewRef.current.scrollTop;
    const scrollTopAbs = Math.abs(scrollTop);

    const newHeight = Math.max(90, 140 - scrollTop);
    const newBlur = Math.min(scrollTopAbs / 4, 10);

    let newOpacity = 0;
    if (newHeight <= 140) {
      newOpacity = Math.min(scrollTopAbs / 10, 1);
    } else {
      newOpacity = 0;
    }

    let newSize = avatarSize;
    let newIndex = logoZIndex;
    let newBottom = logoBottom;

    if (newHeight <= 140) {
      newSize = Math.max(36, Math.min(60, 60 - scrollTopAbs / 2));
    } else {
      newSize = 60;
    }

    if (newHeight <= 90) {
      newIndex = -1;
      newBottom = -40 + (scrollTopAbs - (140 - newHeight));
    } else {
      newIndex = 1;
      newBottom = -40;
    }

    setBannerHeight(newHeight);
    setBannerBlur(newBlur);
    setOverlayOpacity(newOpacity);
    setAvatarSize(newSize);
    setLogoZIndex(newIndex);
    setLogoBottom(newBottom);
  };

  useEffect(() => {
    const spaceViewElement = spaceViewRef.current;
    spaceViewElement.addEventListener("scroll", handleScroll);
    return () => {
      spaceViewElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="space-view" ref={spaceViewRef}>
      <div
        className="space-landing-banner-logo-container"
        style={{ height: `${bannerHeight}px` }}
      >
        <div className="space-landing-banner-container">
          <div onClick={onClose} className="space-view-close-visability">
            <ArrowBottom className="space-view-close-visability-svglogo" />
          </div>
          <div className="space-view-banner-container">
            {bannerUrl ? (
              <img
                src={bannerUrl}
                alt="Room Banner"
                className="space-view-banner-wrapper"
                style={{
                  filter: `blur(${bannerBlur}px)`,
                  transition: "filter 0.2s",
                }}
              />
            ) : (
              <img
                style={{
                  backgroundImage: gradient,
                }}
                className="banner-class-generated"
              />
            )}
          </div>
          <div
            className="banner-overlay"
            style={{
              opacity: overlayOpacity,
              filter: `blur(${bannerBlur}px)`,
              transition: "opacity 0.2s, backdrop-filter 0.2s",
              backgroundColor: bannerUrl
                ? "var(--color-hover-five)"
                : "transparent",
            }}
          ></div>
        </div>
        <div
          className="space-landing-logo-container"
          style={{
            zIndex: logoZIndex,
            bottom: logoBottom,
          }}
        >
          <RoomAvatar
            roomId={spaceId}
            imageSrc={avatarUrl}
            size={avatarSize}
            borderRadius={99}
            borderStyle={true}
          />
        </div>
      </div>
      <div className="space-view-details-view">
        <div className="token-offering-space-container-wrapper">
          <div className="space-view-transparent-background"></div>
          <div className="space-landing-data-container">
            <div className="space-landing-primary-data-container">
              <div className="space-landing-space-header-container-wrapper">
                <div className="space-landing-space-header-container">
                  <span className="space-landing-space-name-text">
                    {data.roomName}
                  </span>
                  <div className="flex-container">
                    {tokenData && tokenData.tokenNewTokenizedSpace ? (
                      <Verify className="verified-contract-address-tick" />
                    ) : (
                      <Verify className="non-verified-contract-address-tick" />
                    )}
                  </div>
                </div>
                <div className="space-landing-space-header-container-data">
                  <span className="space-landing-space-name-creation-timestamp">
                    {formatCreationTimestamp(creationTimestamp)}
                  </span>
                  <div
                    className="space-members-container"
                    onClick={() => setNestedMembersDialog(true)}
                  >
                    <span className="space-landing-space-member-count">
                      <span className="space-landing-space-member-count-number">
                        {memberCount}
                      </span>
                      {memberCount === 1 ? " Member" : " Members"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="social-link-component-box">
              <div
                className="spaceview-settings-container"
                onClick={() => handleOpenDialogWithTab()}
              >
                <Settings className="space-settings-svglogo" />
              </div>
            </div>
          </div>
          <div className="space-landing-desc-data-container">
            <span className="space-landing-space-topic-text-desc">
              {data.roomTopic}
            </span>
          </div>
          <div className="social-link-component-mobile">
            {socialLinks.twitter && (
              <div
                onClick={() => openLink(socialLinks.twitter)}
                className="social-link-container"
              >
                <Twitter className="social-link-svg" />
                <span className="space-landing-space-social-text">Twitter</span>
              </div>
            )}
            {socialLinks.github && (
              <div
                onClick={() => openLink(socialLinks.github)}
                className="social-link-container"
              >
                <Github className="social-link-svg" />
                <span className="space-landing-space-social-text">Github</span>
              </div>
            )}
            {socialLinks.website && (
              <div
                onClick={() => openLink(socialLinks.website)}
                className="social-link-container"
              >
                <Website className="social-link-svg" />
                <span className="space-landing-space-social-text">Website</span>
              </div>
            )}
          </div>
          <div className="discovery-space-sales-container">
            <div className="discovery-toggle-spaces-sales-container">
              <div
                className={`discovery-toggle-spaces-sales-div ${
                  spaceActivityTabSelected === "rooms" ? "selected" : ""
                }`}
                onClick={() => setSpaceActivityTabSelected("rooms")}
              >
                <span className="discovery-toggle-div-text">Rooms</span>
              </div>
              <div
                className={`discovery-toggle-spaces-sales-div ${
                  spaceActivityTabSelected === "token" ? "selected" : ""
                }`}
                onClick={() => setSpaceActivityTabSelected("token")}
              >
                <span className="discovery-toggle-div-text">Token</span>
              </div>
              <div
                className={`discovery-toggle-spaces-sales-div ${
                  spaceActivityTabSelected === "fundraising" ? "selected" : ""
                }`}
                onClick={() => setSpaceActivityTabSelected("fundraising")}
              >
                <span className="discovery-toggle-div-text">Funding</span>
              </div>
              <div
                className={`discovery-toggle-spaces-sales-div ${
                  spaceActivityTabSelected === "govern" ? "selected" : ""
                }`}
                onClick={() => setSpaceActivityTabSelected("govern")}
              >
                <span className="discovery-toggle-div-text">Governance</span>
              </div>
            </div>
          </div>
        </div>
        <div className="space-view-spaceactions-container">
          {spaceActivityTabSelected === "rooms" && spaceId ? (
            <div>
              {" "}
              <SpaceRooms
                space={space}
                roomIds={childRooms}
                spaceId={spaceId}
              />
            </div>
          ) : spaceActivityTabSelected === "fundraising" ? (
            <div className="token-offering-container">
              {isPowerLevelAdminOrAbove && (
                <div onClick={() => setOpenCreatePresaleDialog(true)}>
                  <HighlightsContainer
                    text="Token-Based Crowdfunding"
                    subtext="Kickstart your project by partnering with our Presale
                    launchpad, making fundraising more accessible and
                    manageable."
                    Icon={Price}
                    iconFill="var(--color-accent-seven)"
                  />
                </div>
              )}
              <TokenFundraising
                roomIds={childRooms}
                spaceId={spaceId}
                provider={provider}
              />
            </div>
          ) : spaceActivityTabSelected === "govern" ? (
            <Governance
              space={space}
              roomIds={childRooms}
              spaceId={spaceId}
              data={data}
            />
          ) : spaceActivityTabSelected === "token" ? (
            <Token
              liquidityData={liquidityData}
              data={data}
              spaceId={spaceId}
              avatarUrl={avatarUrl}
            />
          ) : null}
        </div>
        {openRoomSettingsDialog && (
          <Popover
            setOpenDialog={setOpenRoomSettingsDialog}
            direction="right"
            headerText="Space Settings"
          >
            <RoomSettings
              roomId={spaceId}
              setOpenRoomSettingsDialog={setOpenRoomSettingsDialog}
            />
          </Popover>
        )}
        {openCreatePresaleDialog && (
          <Popover
            setOpenDialog={setOpenCreatePresaleDialog}
            direction="right"
            headerText="Create Presale"
          >
            <Presale
              spaceId={spaceId}
              data={data}
              setOpenCreatePresaleDialog={setOpenCreatePresaleDialog}
            />
          </Popover>
        )}
        {nestedMembersDialog && (
          <Popover
            direction="right"
            setOpenDialog={setNestedMembersDialog}
            headerText={"Members"}
          >
            <RoomMembers
              roomId={spaceId}
              setNestedMembersDialog={setNestedMembersDialog}
              setOpenRoomSettingsDialog={setOpenRoomSettingsDialog}
            />
          </Popover>
        )}
      </div>
    </div>
  );
}

export default SpaceView;
