import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Keyboard } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";
import RoomViewHeader from "./RoomViewHeader";
import "./RoomView.scss";
import RoomInput from "./RoomInput";
import RoomEventsTimeline from "./RoomEventsTimeline";
import Mentions from "./Mentions";

function RoomView({ roomId, room, mx }) {
  const [containerHeight, setContainerHeight] = useState("100%");
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [isMentionModalVisible, setIsMentionModalVisible] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [mentionQuery, setMentionQuery] = useState("");
  const [selectedMention, setSelectedMention] = useState({
    displayName: "",
    userId: "",
  });
  const [mentionsContainerHeight, setMentionsContainerHeight] = useState();
  const textareaRef = useRef(null);
  const sendButtonRef = useRef(null);
  const uploadMediaButtonRef = useRef(null);
  const uploadGalleryButtonRef = useRef(null);
  const uploadCameraButtonRef = useRef(null);
  const uploadFolderButtonRef = useRef(null);
  const removeMediaButtonRef = useRef(null);
  const scrollToBottomButtonRef = useRef(null);
  const cancelReplyRef = useRef(null);
  const mentionsAreaRef = useRef(null);

  const handleUserSelect = (userId, displayName) => {
    setIsMentionModalVisible(false);
    setSelectedMention({ displayName, userId });
  };

  useEffect(() => {
    const refs = [
      sendButtonRef,
      uploadMediaButtonRef,
      uploadGalleryButtonRef,
      uploadCameraButtonRef,
      uploadFolderButtonRef,
      removeMediaButtonRef,
      scrollToBottomButtonRef,
      cancelReplyRef,
      mentionsAreaRef,
    ];

    const handleMouseDown = (e) => {
      e.preventDefault();
    };

    refs.forEach((ref) => {
      const button = ref.current;
      if (button) {
        button.addEventListener("mousedown", handleMouseDown);
      }
    });

    return () => {
      refs.forEach((ref) => {
        const button = ref.current;
        if (button) {
          button.removeEventListener("mousedown", handleMouseDown);
        }
      });
    };
  }, []);

  useEffect(() => {
    const getViewportHeight = () => window.innerHeight;

    if (Capacitor.getPlatform() === "ios") {
      const handleKeyboardWillShow = (info) => {
        const viewportHeight = getViewportHeight();
        const newContainerHeight = `calc(${viewportHeight}px - ${info.keyboardHeight}px - env(safe-area-inset-top))`;
        setContainerHeight(newContainerHeight);
        setIsKeyboardVisible(true);
        setKeyboardHeight(info.keyboardHeight); 
      };

      const handleKeyboardDidShow = (info) => {
        const viewportHeight = getViewportHeight();
        const newContainerHeight = `calc(${viewportHeight}px - ${info.keyboardHeight}px - env(safe-area-inset-top))`;
        setContainerHeight(newContainerHeight);
        setIsKeyboardVisible(true);
        setKeyboardHeight(info.keyboardHeight); 
      };

      const handleKeyboardWillHide = () => {
        setContainerHeight("100%");
        setIsKeyboardVisible(false);
        setIsMentionModalVisible(false);
        setKeyboardHeight(0); 
      };

      const handleKeyboardDidHide = () => {
        setContainerHeight("100%");
        setIsKeyboardVisible(false);
        setIsMentionModalVisible(false);
        setKeyboardHeight(0); 
      };

      const addListeners = async () => {
        await Keyboard.addListener("keyboardWillShow", handleKeyboardWillShow);
        await Keyboard.addListener("keyboardDidShow", handleKeyboardDidShow);
        await Keyboard.addListener("keyboardWillHide", handleKeyboardWillHide);
        await Keyboard.addListener("keyboardDidHide", handleKeyboardDidHide);
      };

      addListeners();

      return () => {
        Keyboard.removeAllListeners();
      };
    } else if (Capacitor.getPlatform() === "android") {
      setContainerHeight("100%");
    }
  }, []);

  return (
    <div className="room-view" style={{ height: containerHeight }}>
      <RoomViewHeader room={room} />
      <RoomEventsTimeline
        roomId={roomId}
        room={room}
        scrollToBottomButtonRef={scrollToBottomButtonRef}
        isKeyboardVisible={isKeyboardVisible}
        keyboardHeight={keyboardHeight}
        mx={mx}
      />
      <div
        className={`room-view-mentions-modal ${
          isMentionModalVisible ? "visible" : ""
        }`}
        ref={mentionsAreaRef}
        style={{
          height: `calc(100% - (${containerHeight}) + ${mentionsContainerHeight})`,
        }}
      >
        <Mentions
          roomId={roomId}
          mentionQuery={mentionQuery}
          onClick={handleUserSelect}
          setMentionsContainerHeight={setMentionsContainerHeight}
        />
      </div>
      <RoomInput
        roomId={roomId}
        room={room}
        setContainerHeight={setContainerHeight}
        textareaRef={textareaRef}
        sendButtonRef={sendButtonRef}
        uploadMediaButtonRef={uploadMediaButtonRef}
        uploadGalleryButtonRef={uploadGalleryButtonRef}
        uploadCameraButtonRef={uploadCameraButtonRef}
        uploadFolderButtonRef={uploadFolderButtonRef}
        removeMediaButtonRef={removeMediaButtonRef}
        cancelReplyRef={cancelReplyRef}
        setIsMentionModalVisible={setIsMentionModalVisible}
        setMentionQuery={setMentionQuery}
        selectedMention={selectedMention}
        setSelectedMention={setSelectedMention}
      />
    </div>
  );
}

export default RoomView;
