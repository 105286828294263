import React, { createContext, useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { useAppConnection } from "./AppConnectionContext";
import { decrypt } from "../../util/encryption";
import { Capacitor } from "@capacitor/core";
import SecureStoragePluginAndroid from "../../util/secureStoragePluginAndroid";
import SecureStoragePluginIOS from "../../util/secureStoragePluginIOS";
import { useWalletClient } from "wagmi";

const EthersContext = createContext();

const getSignerFromClient = async (client) => {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain?.id,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address,
  };
  const provider = new ethers.BrowserProvider(transport, network);
  const signer = await provider.getSigner(account?.address);
  return { signer, address: account?.address, chainId: chain?.id };
};

const getSignerFromPrivateKey = async (privateKey) => {
  const alchemyApiKey = import.meta.env.VITE_ALCHEMY_API;
  const network = import.meta.env.VITE_DEFAULT_CHAIN;

  const networks = {
    mainnet: `https://eth-mainnet.g.alchemy.com/v2/${alchemyApiKey}`,
    sepolia: `https://eth-sepolia.g.alchemy.com/v2/${alchemyApiKey}`,
  };

  const providerUrl = networks[network];
  if (!providerUrl) {
    throw new Error("Unsupported network");
  }

  const provider = new ethers.JsonRpcProvider(providerUrl);
  const signer = new ethers.Wallet(privateKey, provider);
  const address = await signer.getAddress();
  const { chainId } = await provider.getNetwork();

  return { signer, address, chainId };
};

export const EthersProvider = ({ children }) => {
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null);
  const [chainId, setChainId] = useState(null);
  const { decryptedPrivateKey, setDecryptedPrivateKey } = useAppConnection();
  const [requirePassword, setRequirePassword] = useState(false);
  const platform = Capacitor.getPlatform();
  const { data: walletClient } = useWalletClient();

  const handlePasswordSubmit = async (password) => {
    try {
      const storedWalletAddress = localStorage.getItem(
        "waivlength_wallet_address"
      );
      const encryptedPrivateKey = await (platform === "ios"
        ? SecureStoragePluginIOS.retrieve({ key: storedWalletAddress })
        : SecureStoragePluginAndroid.retrieve({ key: storedWalletAddress }));

      if (encryptedPrivateKey) {
        const privateKey = decrypt(encryptedPrivateKey.value, password);
        setDecryptedPrivateKey(privateKey);
      }
    } catch (error) {
      console.error("Error retrieving or decrypting private key:", error);
    }
  };

  const fetchSigner = async () => {
    const storedWalletAddress = localStorage.getItem(
      "waivlength_wallet_address"
    );
    const walletArray =
      JSON.parse(localStorage.getItem("waivlength_wallets")) || [];
    const walletData = walletArray.find(
      (wallet) => wallet.walletAddress === storedWalletAddress
    );
    let requirePassword = false;

    if (
      walletData?.createdWallet ||
      walletData?.importedWalletPK ||
      walletData?.importedWalletSP
    ) {
      let privateKey = decryptedPrivateKey;
      if (!privateKey) {
        if (walletData.useFaceId) {
          try {
            const encryptedPrivateKey = await (platform === "ios"
              ? SecureStoragePluginIOS.retrieveWithFaceId({
                  key: storedWalletAddress,
                })
              : SecureStoragePluginAndroid.retrieveWithFaceId({
                  key: storedWalletAddress,
                }));

            if (encryptedPrivateKey) {
              privateKey = decrypt(
                encryptedPrivateKey.value,
                encryptedPrivateKey.password
              );
              setDecryptedPrivateKey(privateKey);
            } else {
              requirePassword = true;
              setRequirePassword(true);
              return {
                signer: null,
                address: null,
                chainId: null,
                requirePassword,
              };
            }
          } catch (error) {
            console.error(
              "Error retrieving private key with biometrics:",
              error
            );
            requirePassword = true;
            setRequirePassword(true);
            return {
              signer: null,
              address: null,
              chainId: null,
              requirePassword,
            };
          }
        } else {
          requirePassword = true;
          setRequirePassword(true);
          return {
            signer: null,
            address: null,
            chainId: null,
            requirePassword,
          };
        }
      }

      if (signer && address && chainId) {
        return {
          signer,
          address,
          chainId,
          requirePassword: false,
        };
      }

      if (privateKey) {
        const { signer, address, chainId } = await getSignerFromPrivateKey(
          privateKey
        );
        setSigner(signer);
        setAddress(address);
        setChainId(chainId);
        return { signer, address, chainId, requirePassword: false };
      }
    }

    if (walletClient) {
      try {
        const { signer, address, chainId } = await getSignerFromClient(
          walletClient
        );
        setSigner(signer);
        setAddress(address);
        setChainId(chainId);
        return { signer, address, chainId, requirePassword: false };
      } catch (error) {
        console.error("Error using wallet client:", error);
      }
    }
  };

  useEffect(() => {
    const updateSigner = async () => {
      if (decryptedPrivateKey) {
        const { signer, address, chainId } = await getSignerFromPrivateKey(
          decryptedPrivateKey
        );
        setSigner(signer);
        setAddress(address);
        setChainId(chainId);
      }
    };

    updateSigner();
  }, [decryptedPrivateKey]);

  return (
    <EthersContext.Provider
      value={{
        handlePasswordSubmit,
        fetchSigner,
      }}
    >
      {children}
    </EthersContext.Provider>
  );
};

export const useEthersContext = () => useContext(EthersContext);
