import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { StatusBar } from "@capacitor/status-bar";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Media } from "@capacitor-community/media";
import { Share } from "@capacitor/share";
import { ReactComponent as RightArrow } from "../../assets/svg/right-arrow.svg";
import { ReactComponent as ShareLink } from "../../assets/svg/share.svg";
import { ReactComponent as Save } from "../../assets/svg/document-download.svg";

import "./MediaPopover.scss";

const MediaPopover = ({ isOpen, onClose, children, body }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMediaOptionsOpen, setIsMediaOptionsOpen] = useState(true);
  const startYRef = useRef(null); // Reference to store the starting Y position of the touch

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
      StatusBar.show();
    }
  }, [isOpen]);

  const handleCloseClick = () => {
    setIsMediaOptionsOpen(false);
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      StatusBar.show();
    }, 300);
  };

  useEffect(() => {
    if (isMediaOptionsOpen) {
      StatusBar.show();
    } else {
      StatusBar.hide();
    }
  }, [isMediaOptionsOpen]);

  const toggleOptions = () => {
    setIsMediaOptionsOpen(!isMediaOptionsOpen);
  };

  const handleShareClick = async () => {
    try {
      const { link, name } = body.props || {};

      if (!link) {
        console.error("No media link available to share.");
        return;
      }

      const response = await fetch(link);
      const blob = await response.blob();

      const fileType = blob.type.split("/")[1];
      const fileName = `${name || "shared-media"}.${fileType}`;

      const savedFile = await Filesystem.writeFile({
        path: fileName,
        data: await blobToBase64(blob),
        directory: Directory.Cache,
      });

      await Share.share({
        files: [savedFile.uri],
        dialogTitle: "Share Media",
      });
    } catch (error) {
      console.error("Error sharing media:", error);
    }
  };

  const handleSaveClick = async () => {
    try {
      const { link, name } = body.props || {};

      if (!link) {
        console.error("No media link available to save.");
        return;
      }

      const response = await fetch(link);
      const blob = await response.blob();

      const mimeType = blob.type;
      const fileType = mimeType.split("/")[1];

      const fileName = `${name || "saved-media"}.${fileType}`;

      const base64Data = await blobToBase64(blob);

      const saveOptions = {
        path: `data:${mimeType};base64,${base64Data}`,
        fileName: fileName.split(".")[0],
      };

      if (mimeType.startsWith("image/")) {
        await Media.savePhoto(saveOptions);
        alert("Image saved to your device!");
      } else if (mimeType.startsWith("video/")) {
        await Media.saveVideo(saveOptions);
        alert("Video saved to your device!");
      } else {
        console.error("Unsupported media type:", mimeType);
      }
    } catch (error) {
      console.error("Error saving media:", error);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => {
        console.error("Error converting blob to base64:", error);
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleContentClick = (e) => {
    if (
      !e.target.closest(".media-popover-options.top") &&
      !e.target.closest(".media-popover-options.bottom")
    ) {
      toggleOptions();
    }
  };

  const handleTouchStart = (e) => {
    startYRef.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (!startYRef.current) return;

    const currentY = e.touches[0].clientY;
    const diffY = currentY - startYRef.current;

    if (diffY > 100) {
      // Threshold for swipe-down detection
      handleCloseClick();
    }
  };

  return isOpen
    ? ReactDOM.createPortal(
        <div
          className={`media-popover-overlay ${isVisible ? "active" : ""}`}
          onClick={handleCloseClick}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`media-popover-content ${isVisible ? "active" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              handleContentClick(e);
            }}
          >
            {children}
            {isMediaOptionsOpen && (
              <>
                <div className="media-popover-options top active">
                  <div
                    className="media-popover-back"
                    onClick={handleCloseClick}
                  >
                    <RightArrow className="media-popover-back-svglogo" />
                  </div>
                </div>
                <div className="media-popover-options bottom active">
                  <div
                    className="media-popover-back"
                    onClick={handleShareClick}
                  >
                    <ShareLink className="media-popover-share-svglogo" />
                  </div>
                  <div className="media-popover-back" onClick={handleSaveClick}>
                    <Save className="media-popover-save-svglogo" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>,
        document.getElementById("custom-modal-root")
      )
    : null;
};

export default MediaPopover;
