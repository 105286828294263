import React from "react";
import PropTypes from "prop-types";
import "./WalletComponent.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import useMatrix from "../../hooks/useMatrix";
import { formatDisplayName } from "../../../util/formatDisplayName";
import initMatrix from "../../../client/initMatrix";

const WalletComponent = ({ handleDialogOpen }) => {
  const { user } = useMatrix();
  if (!user || !initMatrix || !initMatrix.matrixClient) {
    return null;
  }
  const avatarSrc = initMatrix.matrixClient.mxcUrlToHttp(
    user.avatarUrl,
    36,
    36,
    "crop"
  );
  return (
    <div className="wallet-component-container" onClick={handleDialogOpen}>
      <UserAvatar size={28} userId={user.userId} imageSrc={avatarSrc} />
      <span className="wallet-component-text">
        {formatDisplayName(user.displayName)}
      </span>
    </div>
  );
};

WalletComponent.propTypes = {
  handleDialogOpen: PropTypes.func.isRequired,
};

export default WalletComponent;
