import React from "react";
import { twemojify } from "../../../util/twemojify";
import initMatrix from "../../../client/initMatrix";
import { getUsername, getUsernameOfRoomMember } from "../../../util/matrixUtil";
import { formatDisplayName } from "../../../util/formatDisplayName";

function getTimelineJSXMessages() {
  return {
    join(user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" joined the room"}
        </>
      );
    },
    leave(user, reason) {
      const reasonMsg = typeof reason === "string" ? `: ${reason}` : "";
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" left the room"}
          {twemojify(reasonMsg)}
        </>
      );
    },
    invite(inviter, user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(inviter)}</span>
          {" invited "}
          <span className="timeline-change-text">{twemojify(user)}</span>
        </>
      );
    },
    cancelInvite(inviter, user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(inviter)}</span>
          {" canceled "}
          <span className="timeline-change-text">{twemojify(user)}</span>
          {"'s invite"}
        </>
      );
    },
    rejectInvite(user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" rejected the invitation"}
        </>
      );
    },
    kick(actor, user, reason) {
      const reasonMsg = typeof reason === "string" ? `: ${reason}` : "";
      return (
        <>
          <span className="timeline-change-text">{twemojify(actor)}</span>
          {" kicked "}
          <span className="timeline-change-text">{twemojify(user)}</span>
          {twemojify(reasonMsg)}
        </>
      );
    },
    ban(actor, user, reason) {
      const reasonMsg = typeof reason === "string" ? `: ${reason}` : "";
      return (
        <>
          <span className="timeline-change-text">{twemojify(actor)}</span>
          {" banned "}
          <span className="timeline-change-text">{twemojify(user)}</span>
          {twemojify(reasonMsg)}
        </>
      );
    },
    unban(actor, user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(actor)}</span>
          {" unbanned "}
          <span className="timeline-change-text">{twemojify(user)}</span>
        </>
      );
    },
    avatarSets(user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" set a avatar"}
        </>
      );
    },
    avatarChanged(user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" changed their avatar"}
        </>
      );
    },
    avatarRemoved(user) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" removed their avatar"}
        </>
      );
    },
    nameSets(user, newName) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" set display name to "}
          <span className="timeline-change-text">{twemojify(newName)}</span>
        </>
      );
    },
    nameChanged(user, newName) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" changed their display name to "}
          <span className="timeline-change-text">{twemojify(newName)}</span>
        </>
      );
    },
    nameRemoved(user, lastName) {
      return (
        <>
          <span className="timeline-change-text">{twemojify(user)}</span>
          {" removed their display name "}
          <span className="timeline-change-text">{twemojify(lastName)}</span>
        </>
      );
    },
  };
}

function getUsersActionJsx(roomId, userIds, actionStr) {
  const room = initMatrix.matrixClient.getRoom(roomId);
  const getUserDisplayName = (userId) => {
    if (room?.getMember(userId))
      return getUsernameOfRoomMember(room.getMember(userId));
    return getUsername(userId);
  };
  const getUserJSX = (userId) => <span className="timeline-change-text">{twemojify(getUserDisplayName(userId))}</span>;
  if (!Array.isArray(userIds)) return "Idle";
  if (userIds.length === 0) return "Idle";
  const MAX_VISIBLE_COUNT = 3;

  const u1Jsx = getUserJSX(userIds[0]);
  // eslint-disable-next-line react/jsx-one-expression-per-line
  if (userIds.length === 1)
    return (
      <>
        {u1Jsx} is {actionStr}
      </>
    );

  const u2Jsx = getUserJSX(userIds[1]);
  // eslint-disable-next-line react/jsx-one-expression-per-line
  if (userIds.length === 2)
    return (
      <>
        {u1Jsx} and {u2Jsx} are {actionStr}
      </>
    );

  const u3Jsx = getUserJSX(userIds[2]);
  if (userIds.length === 3) {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return (
      <>
        {u1Jsx}, {u2Jsx} and {u3Jsx} are {actionStr}
      </>
    );
  }

  const othersCount = userIds.length - MAX_VISIBLE_COUNT;
  // eslint-disable-next-line react/jsx-one-expression-per-line
  return (
    <>
      {u1Jsx}, {u2Jsx}, {u3Jsx} and {othersCount} others are {actionStr}
    </>
  );
}

function parseTimelineChange(mEvent) {
  const tJSXMsgs = getTimelineJSXMessages();
  const makeReturnObj = (variant, content) => ({
    variant,
    content,
  });
  const content = mEvent.getContent();
  const prevContent = mEvent.getPrevContent();
  const sender = mEvent.getSender();
  const senderName = formatDisplayName(getUsername(sender));
  const userName = formatDisplayName(getUsername(mEvent.getStateKey()));

  switch (content.membership) {
    case "invite":
      return makeReturnObj("invite", tJSXMsgs.invite(senderName, userName));
    case "ban":
      return makeReturnObj(
        "leave",
        tJSXMsgs.ban(senderName, userName, content.reason)
      );
    case "join":
      if (prevContent.membership === "join") {
        if (content.displayname !== prevContent.displayname) {
          if (typeof content.displayname === "undefined")
            return makeReturnObj(
              "avatar",
              tJSXMsgs.nameRemoved(
                sender,
                formatDisplayName(prevContent.displayname)
              )
            );
          if (typeof prevContent.displayname === "undefined")
            return makeReturnObj(
              "avatar",
              tJSXMsgs.nameSets(sender, formatDisplayName(content.displayname))
            );
          return makeReturnObj(
            "avatar",
            tJSXMsgs.nameChanged(
              formatDisplayName(prevContent.displayname),
              formatDisplayName(content.displayname)
            )
          );
        }
        if (content.avatar_url !== prevContent.avatar_url) {
          if (typeof content.avatar_url === "undefined")
            return makeReturnObj(
              "avatar",
              tJSXMsgs.avatarRemoved(formatDisplayName(content.displayname))
            );
          if (typeof prevContent.avatar_url === "undefined")
            return makeReturnObj(
              "avatar",
              tJSXMsgs.avatarSets(formatDisplayName(content.displayname))
            );
          return makeReturnObj(
            "avatar",
            tJSXMsgs.avatarChanged(formatDisplayName(content.displayname))
          );
        }
        return null;
      }
      return makeReturnObj("join", tJSXMsgs.join(senderName));
    case "leave":
      if (sender === mEvent.getStateKey()) {
        switch (prevContent.membership) {
          case "invite":
            return makeReturnObj(
              "invite-cancel",
              tJSXMsgs.rejectInvite(senderName)
            );
          default:
            return makeReturnObj(
              "leave",
              tJSXMsgs.leave(senderName, content.reason)
            );
        }
      }
      switch (prevContent.membership) {
        case "invite":
          return makeReturnObj(
            "invite-cancel",
            tJSXMsgs.cancelInvite(senderName, userName)
          );
        case "ban":
          return makeReturnObj("other", tJSXMsgs.unban(senderName, userName));
        default:
          return makeReturnObj(
            "leave",
            tJSXMsgs.kick(senderName, userName, content.reason)
          );
      }
    default:
      return null;
  }
}

export { getTimelineJSXMessages, getUsersActionJsx, parseTimelineChange };
