import React, { useState } from "react";
import "./SetupWallet.scss";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";
import { useAppConnection } from "../../contexts/AppConnectionContext";
import { Capacitor } from "@capacitor/core";
import SecureStoragePluginIOS from "../../../util/secureStoragePluginIOS";
import { decrypt } from "../../../util/encryption";
import SecureStoragePluginAndroid from "../../../util/secureStoragePluginAndroid";

const platform = Capacitor.getPlatform();

const ReconnectWallet = ({ walletData, setOpenDialog }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { handleWalletSetup } = useAppConnection();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const handleReconnect = async () => {
    setLoading(true);
    setErrorMessage("");

    try {
      const encryptedPrivateKey = await (platform === "ios"
        ? SecureStoragePluginIOS.retrieve({ key: walletData.walletAddress })
        : SecureStoragePluginAndroid.retrieve({
            key: walletData.walletAddress,
          }));

      if (encryptedPrivateKey && encryptedPrivateKey.value) {
        try {
          const privateKey = decrypt(encryptedPrivateKey.value, password);
          handleWalletSetup(
            walletData.walletAddress,
            privateKey,
            walletData.useFaceId,
            walletData.createdWallet,
            walletData.importedWallet
          );
          setOpenDialog(false);
        } catch (error) {
          console.error("Decryption error:", error);
          setErrorMessage("Incorrect password. Please try again.");
        }
      } else {
        setErrorMessage("Failed to retrieve the encrypted private key");
      }
    } catch (error) {
      console.error("Error reconnecting wallet:", error);
      setErrorMessage("Error reconnecting wallet. Please check your password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="setup-wallet-container">
      <span className="create-space-secondary-text">Wallet Address</span>
      <div className="setup-wallet-address-container">
        <span className="setup-wallet-address-text">
          {walletData?.walletAddress}
        </span>
      </div>
      <span className="create-space-secondary-text">Enter Password</span>
      <CustomInput
        placeholder="Password"
        type="password"
        onChange={handlePasswordChange}
        autoFocus={[true, 300]}
        value={password}
      />
      <div
        className="create-container-button-create"
        onClick={handleReconnect}
        disabled={loading}
      >
        {loading ? (
          <Loader
            size="24px"
            dotSize="6px"
            color="var(--dark)"
            multiplier={1.4}
          />
        ) : (
          <span className="create-room-button-text">Reconnect</span>
        )}
      </div>
      {errorMessage && (
        <div className="create-room-error-text">{errorMessage}</div>
      )}
    </div>
  );
};

export default ReconnectWallet;
