import React, { useState, useEffect } from "react";
import "./NotificationsPage.scss";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import * as roomActions from "../../../client/action/room";
import { selectTab } from "../../../client/action/navigation";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import Loader from "../../atoms/loader/Loader";
import { formatDisplayName } from "../../../util/formatDisplayName";

function NotificationsPage() {
  const [procInvite, changeProcInvite] = useState(new Set());
  const [loadingStates, setLoadingStates] = useState({});
  const { setDmRoomId, setSpaceId, setRoomId } = useRoomSelect();

  const handleInvite = async (roomId, isDM, parentRoomId = null) => {
    const isJoined =
      initMatrix.matrixClient.getRoom(roomId)?.getMyMembership() === "join";
    if (isJoined) {
      processInvite(roomId, isDM, parentRoomId);
      setLoadingStates((prev) => ({ ...prev, [roomId]: false }));
    } else {
      await joinInviteRoom(roomId, isDM, parentRoomId);
    }
  };

  const joinInviteRoom = async (roomId, isDM, parentRoomId = null) => {
    try {
      const { roomId: joinedRoomId } = await roomActions.join(roomId, isDM);
      const room = initMatrix.matrixClient.getRoom(joinedRoomId);
      const parentEvent = room?.currentState.events
        .get("m.space.parent")
        ?.values()
        .next().value;
      const newParentRoomId = parentEvent ? parentEvent.getStateKey() : null;

      if (newParentRoomId && newParentRoomId !== parentRoomId) {
        await joinInviteRoom(newParentRoomId, false, newParentRoomId);
        handleInvite(joinedRoomId, isDM, newParentRoomId);
      } else {
        handleInvite(joinedRoomId, isDM, parentRoomId);
      }
    } catch (error) {
      console.error("Failed to join room:", error);
    }
  };

  const processInvite = async (roomId, isDM, parentRoomId = null) => {
    const room = initMatrix.matrixClient.getRoom(roomId);
    if (!room) {
      setLoadingStates((prev) => ({ ...prev, [roomId]: false }));
      return;
    }

    const isSpace = room?.getType() === "m.space";

    if (isDM) {
      selectTab(cons.tabs.DIRECTS);
      setDmRoomId(roomId);
    } else if (isSpace) {
      selectTab(cons.tabs.HOME);
      setSpaceId(roomId);
    } else if (parentRoomId) {
      selectTab(cons.tabs.HOME);
      setSpaceId(parentRoomId);
      setRoomId(roomId);
    } else {
      selectTab(cons.tabs.HOME);
      setSpaceId(null);
      setRoomId(roomId);
    }
  };

  async function acceptInvite(roomId, isDM) {
    setLoadingStates((prev) => ({ ...prev, [roomId]: true }));
    procInvite.add(roomId);
    changeProcInvite(new Set(Array.from(procInvite)));
    await handleInvite(roomId, isDM);
  }

  function rejectInvite(roomId, isDM) {
    procInvite.add(roomId);
    changeProcInvite(new Set(Array.from(procInvite)));
    roomActions.leave(roomId, isDM);
  }

  function updateInviteList(roomId) {
    if (procInvite.has(roomId)) procInvite.delete(roomId);
    changeProcInvite(new Set(Array.from(procInvite)));
  }

  useEffect(() => {
    initMatrix.roomList.on(
      cons.events.roomList.INVITELIST_UPDATED,
      updateInviteList
    );

    return () => {
      initMatrix.roomList.removeListener(
        cons.events.roomList.INVITELIST_UPDATED,
        updateInviteList
      );
    };
  }, [procInvite]);

  function renderRoomTile(roomId) {
    const mx = initMatrix.matrixClient;
    const room = mx.getRoom(roomId);
    const inviterName =
      room.getMember(mx.getUserId())?.events?.member?.getSender?.() ?? "";
    const user = mx.getUser(inviterName);
    const displayName = user.displayName;
    const roomName = room.name;
    const avatarUrl =
      room.getAvatarUrl(initMatrix.matrixClient.baseUrl, 42, 42, "crop") ||
      null;

    return (
      <div className="notifications-page-notification-container">
        <div className="notifications-page-notification-container-avatar">
          <RoomAvatar
            imageSrc={avatarUrl}
            roomId={roomId}
            text={roomName}
            size={42}
            borderRadius={8}
          />
        </div>
        <div className="notifications-page-notification-container-content">
          <span className="notifications-page-notification-text">
            <span className="notifications-page-notification-text-lower">
              You have been invited by{" "}
            </span>
            <span>{formatDisplayName(displayName)}</span>
            <span className="notifications-page-notification-text-lower">
              {" "}
              to join{" "}
            </span>
            <span>{roomName}</span>
          </span>
          <div className="notifications-page-notification-actions-container">
            <div
              onClick={() => rejectInvite(room.roomId, false)}
              className="notifications-page-invite-container-reject"
            >
              <span className="notifications-page-notification-text-reject">
                Reject
              </span>
            </div>
            <div
              onClick={() => acceptInvite(room.roomId, false)}
              className="notifications-page-invite-container-accept"
            >
              {loadingStates[roomId] ? (
                <Loader
                  size="20px"
                  dotSize="6px"
                  color="var(--dark)"
                  multiplier={1.4}
                />
              ) : (
                <span className="notifications-page-notification-text-accept">
                  Accept
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDirectInvite(roomId) {
    const mx = initMatrix.matrixClient;
    const room = mx.getRoom(roomId);
    if (room === null) return null;
    const inviterName =
      room.getMember(mx.getUserId())?.events?.member?.getSender?.() ?? "";
    const user = mx.getUser(inviterName);
    const displayName = user.displayName;
    const name = formatDisplayName(displayName);
    const getAvatarUrl = (userId) => {
      const roomMember = room.getMember(userId);
      const avatarMxc =
        roomMember?.getMxcAvatarUrl?.() || mx.getUser(userId)?.avatarUrl;
      return avatarMxc && avatarMxc !== "null"
        ? mx.mxcUrlToHttp(avatarMxc, 42, 42, "crop")
        : null;
    };
    return (
      <div key={roomId} className="notifications-page-notification-container">
        <div className="notifications-page-notification-container-avatar">
          <UserAvatar
            size={42}
            userId={inviterName}
            imageSrc={getAvatarUrl(displayName)}
          />
        </div>
        <div className="notifications-page-notification-container-content">
          <span className="notifications-page-notification-text">
            <span className="notifications-page-notification-text-lower">
              Invited to join chat with{" "}
            </span>
            <span>{name}</span>
          </span>
          <div className="notifications-page-notification-actions-container">
            <div
              onClick={() => rejectInvite(room.roomId, true)}
              className="notifications-page-invite-container-reject"
            >
              <span className="notifications-page-notification-text-reject">
                Reject
              </span>
            </div>
            <div
              onClick={() => acceptInvite(room.roomId, true)}
              className="notifications-page-invite-container-accept"
            >
              {loadingStates[roomId] ? (
                <Loader
                  size="20px"
                  dotSize="6px"
                  color="var(--dark)"
                  multiplier={1.4}
                />
              ) : (
                <span className="notifications-page-notification-text-accept">
                  Accept
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="notifications-page-container">
      <div className="rooms-category-header-text">
        <span className="notifications-page-header-text-color">
          Notifications
        </span>
      </div>
      <div>
        {initMatrix.roomList.inviteDirects.size !== 0 && (
          <div className="notifications-page-invitation-header">
            <span className="notifications-page-invitation-header-text">
              Direct Messages
            </span>
          </div>
        )}
        <div>
          {Array.from(initMatrix.roomList.inviteDirects).map(
            renderDirectInvite
          )}
        </div>
        {initMatrix.roomList.inviteSpaces.size !== 0 && (
          <div className="notifications-page-invitation-header">
            <span className="notifications-page-invitation-header-text">
              Spaces
            </span>
          </div>
        )}
        {Array.from(initMatrix.roomList.inviteSpaces).map((room, index) => (
          <div key={index}>{renderRoomTile(room)}</div>
        ))}
        {initMatrix.roomList.inviteRooms.size !== 0 && (
          <div className="notifications-page-invitation-header">
            <span className="notifications-page-invitation-header-text">
              Rooms
            </span>
          </div>
        )}
        {Array.from(initMatrix.roomList.inviteRooms).map((room, index) => (
          <div key={index}>{renderRoomTile(room)}</div>
        ))}
      </div>
    </div>
  );
}

export default NotificationsPage;
