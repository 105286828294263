export const parseTimeLeft = (timeLeftString) => {
  // Initialize time structure with all fields set to "00"
  const timeStructure = {
    days: "0",
    hours: "0",
    mins: "0",
    secs: "0",
  };

  // Enhance the regex to match both shorthand (e.g., "6m") and verbose (e.g., "11 secs") forms
  const regex = /(\d+)\s*(d|h|m|s|days?|hours?|mins?|secs?)/g;
  let matches;
  while ((matches = regex.exec(timeLeftString)) !== null) {
    const [_, value, unit] = matches;
    const formattedValue = value.padStart(2, "0");
    switch (
      unit[0] // Use the first character to simplify matching both shorthand and full words
    ) {
      case "d":
        timeStructure.days = formattedValue;
        break;
      case "h":
        timeStructure.hours = formattedValue;
        break;
      case "m":
        timeStructure.mins = formattedValue;
        break;
      case "s":
        timeStructure.secs = formattedValue;
        break;
      default:
        break;
    }
  }

  return [
    { label: "days", value: timeStructure.days },
    { label: "hours", value: timeStructure.hours },
    { label: "mins", value: timeStructure.mins },
    { label: "secs", value: timeStructure.secs },
  ];
};
