import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./Settings.scss";
import initMatrix from "../../../client/initMatrix";
import { getRandomGradient } from "../../../util/gradients";
import Modal from "../../atoms/modal/Modal";
import CustomInput from "../../atoms/input/CustomInput";

function RoomDetails({ roomId }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const isSpace = room?.getType() === "m.space";
  const { currentState } = room;
  const roomNameFormatted = room.name;
  const roomTopicFormatted = currentState
    .getStateEvents("m.room.topic")[0]
    ?.getContent().topic;
  const userId = mx.getUserId();
  const canChangeName = currentState.maySendStateEvent("m.room.name", userId);
  const canChangeTopic = currentState.maySendStateEvent("m.room.topic", userId);

  const [roomName, setRoomName] = useState(roomNameFormatted);
  const [roomTopic, setRoomTopic] = useState(roomTopicFormatted);
  const [socialLinks, setSocialLinks] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [error, setError] = useState("");

  const twitterUrlRegex =
    /^(https?:\/\/)?(www\.)?(x|twitter)\.com\/[a-zA-Z0-9_]+$/;
  const githubUrlRegex = /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9_-]+$/;
  const websiteUrlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\/[a-zA-Z0-9.-]*)*$/;

  const validateRoomName = (name) => {
    const errors = [];

    if (!name) {
      errors.push("Space name is missing");
    } else if (name.length === 0) {
      errors.push("Space name cannot be empty");
    } else if (name.length > 24) {
      errors.push("Space name cannot exceed 24 characters");
    }

    return errors.length ? errors.join(". ") : "";
  };

  const validateRoomTopic = (topic) => {
    if (topic.length > 140) {
      return "Topic cannot exceed 140 characters.";
    }
    return "";
  };

  const getRoomSocialLinks = (room) => {
    const socialLinksEvent = currentState.getStateEvents(
      "custom.social_links",
      ""
    );
    return socialLinksEvent ? socialLinksEvent.getContent() : {};
  };

  const updateRoomSocialLinks = async (room, socialLinks) => {
    try {
      await room.client.sendStateEvent(
        room.roomId,
        "custom.social_links",
        socialLinks,
        ""
      );
    } catch (error) {
      console.error("Failed to update social links:", error);
    }
  };

  const handleInputChange = (e, key) => {
    setSocialLinks((prevLinks) => ({
      ...prevLinks,
      [key]: e.target.value,
    }));
    setHasChanges(true);
    setError("");
  };

  useEffect(() => {
    const links = getRoomSocialLinks(room);
    setSocialLinks(links);
  }, [room]);

  const handleInputChangeRoomName = (e) => {
    const name = e.target.value;
    setRoomName(name);
    const validationError = validateRoomName(name);
    setError(validationError);
    setHasChanges(
      validationError === "" &&
        (name !== roomNameFormatted ||
          roomTopic !== roomTopicFormatted ||
          JSON.stringify(socialLinks) !==
            JSON.stringify(getRoomSocialLinks(room)))
    );
  };

  const handleInputChangeRoomTopic = (e) => {
    const topic = e.target.value;
    setRoomTopic(topic);
    const validationError = validateRoomTopic(topic);
    setError(validationError);
    setHasChanges(
      validationError === "" &&
        (roomName !== roomNameFormatted ||
          topic !== roomTopicFormatted ||
          JSON.stringify(socialLinks) !==
            JSON.stringify(getRoomSocialLinks(room)))
    );
  };

  const handleUnifiedSubmit = async (e) => {
    e.preventDefault();

    const nameError = validateRoomName(roomName);
    const topicError = validateRoomTopic(roomTopic);
    const socialLinkErrors = validateSocialLinks(socialLinks);

    if (nameError || topicError || socialLinkErrors.length > 0) {
      setError(nameError || topicError || socialLinkErrors.join(", "));
      return;
    }

    let changesSaved = false;

    if (roomName !== roomNameFormatted && roomName.trim() !== "") {
      try {
        await mx.setRoomName(roomId, roomName);
        changesSaved = true;
      } catch (err) {
        console.error("Error setting room name:", err);
      }
    }

    if (roomTopic !== roomTopicFormatted && roomTopic.trim() !== "") {
      try {
        await mx.setRoomTopic(roomId, roomTopic);
        changesSaved = true;
      } catch (err) {
        console.error("Error setting room topic:", err);
      }
    }

    if (hasChanges) {
      try {
        await updateRoomSocialLinks(room, socialLinks);
        changesSaved = true;
      } catch (error) {
        console.error("Failed to update social links:", error);
      }
    }

    if (changesSaved) {
      setHasChanges(false);
      setError("");
    }
  };

  const validateSocialLinks = (links) => {
    const errors = [];

    if (links.twitter && !twitterUrlRegex.test(links.twitter)) {
      errors.push("Twitter URL is not in valid format");
    }

    if (links.github && !githubUrlRegex.test(links.github)) {
      errors.push("Github URL is not in valid format");
    }

    if (links.website && !websiteUrlRegex.test(links.website)) {
      errors.push("Website URL is not in valid format");
    }

    return errors;
  };

  const [avatarUrl, setAvatarUrl] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uploadImageRef = useRef(null);
  const [uploadingFor, setUploadingFor] = useState(null);

  useEffect(() => {
    const room = mx.getRoom(roomId);

    const avatarEvent = room?.currentState.getStateEvents("m.room.avatar", "");
    const avatarUrl = avatarEvent
      ? mx.mxcUrlToHttp(avatarEvent.getContent().url, 48, 48, "crop")
      : null;
    setAvatarUrl(avatarUrl);

    const bannerEvent = room?.currentState.getStateEvents(
      "custom.space_banner",
      ""
    );
    const bannerUrl = bannerEvent
      ? mx.mxcUrlToHttp(bannerEvent.getContent().url, 22000, 10000, "scale")
      : null;
    setBannerUrl(bannerUrl);
  }, [roomId, mx]);

  const { gradient } = getRandomGradient(roomId);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!file.type.match("image/jpeg") && !file.type.match("image/png")) {
      alert("Please select a JPG or PNG file.");
      return;
    }

    try {
      const response = await mx.uploadContent(file);
      const mxcUrl = response.content_uri;

      if (uploadingFor === "avatar") {
        await mx.sendStateEvent(roomId, "m.room.avatar", { url: mxcUrl }, "");
        const newAvatarUrl = mx.mxcUrlToHttp(mxcUrl, 48, 48, "crop");
        setAvatarUrl(newAvatarUrl);
      } else if (uploadingFor === "banner") {
        await mx.sendStateEvent(
          roomId,
          "custom.space_banner",
          { url: mxcUrl },
          ""
        );
        const newBannerUrl = mx.mxcUrlToHttp(mxcUrl, 22000, 10000, "scale");
        setBannerUrl(newBannerUrl);
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const removeAvatar = async () => {
    try {
      await mx.sendStateEvent(roomId, "m.room.avatar", { url: null }, "");
      setAvatarUrl(null);
    } catch (error) {
      console.error("Failed to remove avatar:", error);
    }
  };

  const removeBanner = async () => {
    try {
      await mx.sendStateEvent(roomId, "custom.space_banner", { url: null }, "");
      setBannerUrl(null);
    } catch (error) {
      console.error("Failed to remove banner:", error);
    }
  };

  return (
    <div className="room-settings-body">
      {isSpace && (
        <div className="room-settings-room-profile">
          <div
            className="room-details-banner-container"
            style={{
              backgroundImage: bannerUrl ? `url(${bannerUrl})` : gradient,
            }}
          >
            {bannerUrl && (
              <img
                src={bannerUrl}
                alt="Room Banner"
                className="room-details-banner-image"
              />
            )}
            <div className="room-details-avatar-container">
              {avatarUrl ? (
                <img
                  src={avatarUrl}
                  alt="Space Avatar"
                  className="room-details-avatar-image"
                />
              ) : (
                <div
                  className="room-details-avatar-gradient"
                  style={{
                    backgroundImage: gradient,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
      {canChangeName && isSpace && (
        <div className="edit-media-container-profile" onClick={toggleModal}>
          <span className="edit-media-container-text-profile">Edit Media</span>
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        <div className="create-space-media-modal-content">
          <input
            ref={uploadImageRef}
            id="imageInput"
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={uploadImage}
          />
          <div
            onClick={() => {
              document.getElementById("imageInput").click();
              setUploadingFor("avatar");
            }}
            className="create-space-media-button-upload"
          >
            <span className="create-space-media-button-text-upload">
              Upload Avatar
            </span>
          </div>
          {avatarUrl && (
            <div onClick={removeAvatar} className="create-space-media-button">
              <span className="create-space-media-button-text">
                Remove Avatar
              </span>
            </div>
          )}
          <div
            onClick={() => {
              document.getElementById("imageInput").click();
              setUploadingFor("banner");
            }}
            className="create-space-media-button-upload"
          >
            <span className="create-space-media-button-text-upload">
              Upload Banner
            </span>
          </div>
          {bannerUrl && (
            <div onClick={removeBanner} className="create-space-media-button">
              <span className="create-space-media-button-text">
                Remove Banner
              </span>
            </div>
          )}
        </div>
      </Modal>
      <div className="room-settings-room-profile-name">
        <span className="space-landing-space-data-title-text">
          {isSpace ? "Space Name" : "Room Name"}
        </span>
        <CustomInput
          value={roomName}
          onChange={handleInputChangeRoomName}
          disabled={!canChangeName}
        />
      </div>
      <div className="room-settings-room-profile-topic">
        <span className="space-landing-space-data-title-text">
          {isSpace ? "Space Topic" : "Room Topic"}
        </span>
        <CustomInput
          value={roomTopic}
          onChange={handleInputChangeRoomTopic}
          resizable={true}
          disabled={!canChangeTopic}
        />
      </div>
      {isSpace && canChangeName && (
        <div className="room-settings-room-profile-socials">
          <span className="room-settings-room-profile-socials-text">
            Socials
          </span>
          <CustomInput
            value={socialLinks.github || ""}
            onChange={(e) => handleInputChange(e, "github")}
          />
          <CustomInput
            value={socialLinks.twitter || ""}
            onChange={(e) => handleInputChange(e, "twitter")}
          />
          <CustomInput
            value={socialLinks.website || ""}
            onChange={(e) => handleInputChange(e, "website")}
          />
        </div>
      )}

      {error && <span className="create-room-error-text">{error}</span>}
      <div className="room-details-save-wrapper">
        <div
          className={`save-changes-container-profile ${
            hasChanges ? "has-changes" : ""
          }`}
          onClick={handleUnifiedSubmit}
        >
          <span className="save-changes-container-text-profile">
            Save Changes
          </span>
        </div>
      </div>
    </div>
  );
}

RoomDetails.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default RoomDetails;
