import React from "react";
import ReactDOM from "react-dom/client";
import { enableMapSet } from "immer";
import "./index.scss";
import "@ionic/react/css/core.css";
import { setupIonicReact } from "@ionic/react";
import App from "./App";
import { ThemeProvider } from "./app/contexts/ThemeContext";

enableMapSet();
setupIonicReact();

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);