import React, { useState, useEffect } from "react";
import "./CreateSpace.scss";
import { ReactComponent as GalleryEdit } from "../../assets/svg/gallery-edit.svg";
import { ReactComponent as Github } from "../../assets/svg/github-icon.svg";
import { ReactComponent as Twitter } from "../../assets/svg/twitter-icon.svg";
import { ReactComponent as Website } from "../../assets/svg/website-icon.svg";
import CustomInput from "../../atoms/input/CustomInput";
import { handleCreateRoom } from "../../../client/action/handleCreateRoom";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import { createToken } from "../../../util/createToken";
import { useLoading } from "../../contexts/LoadingContext";
import Modal from "../../atoms/modal/Modal";
import { validateCreateSpace } from "../../../util/validateCreateSpace";
import { validateTransaction } from "../../../util/txnValidation";
import Popover from "../../atoms/popover/Popover";
import Password from "../../molecules/wallet-settings/Password";
import { useEthersContext } from "../../contexts/EthersContext";
import getProvider from "../../../util/getProvider";

const CreateSpace = ({ setOpenSpaceDialog, scrollToInput }) => {
  const [selectedPlaceholder, setSelectedPlaceholder] = useState();
  const [selectedButton, setSelectedButton] = useState("Github");
  const { setSpaceId } = useRoomSelect();
  const [spaceData, setSpaceData] = useState({
    spaceName: "",
    spaceDesc: "",
    spaceSocialTwitter: "",
    spaceSocialGithub: "",
    spaceSocialWebsite: "",
    spaceAvatarUrl: null,
    spaceBannerUrl: null,
    spaceJoinRule: "public",
    tokenName: "",
    tokenSymbol: "",
    tokenTotalSupply: "",
    tokenNewTokenizedSpace: true,
    tokenContractAddress: "",
    tokenContractHash: "",
    etherscanLink: "",
  });
  const [currentField, setCurrentField] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [provider, setProvider] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setSpaceData((prevData) => ({ ...prevData, [field]: value }));
  };

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const { provider } = await getProvider();
        setProvider(provider);
      } catch (error) {
        console.error("Error fetching provider:", error);
      }
    };

    fetchProvider();
  }, []);

  const { showLoading, hideLoading } = useLoading();
  const { fetchSigner, handlePasswordSubmit } = useEthersContext();
  const [openDialog, setOpenDialog] = useState(false);

  const handlePasswordInput = (password) => {
    handlePasswordSubmit(password);
    setOpenDialog(false);
  };

  const createSpace = async () => {
    const { signer, address, chainId, requirePassword } = await fetchSigner();
    showLoading(
      [
        "Creating new space",
        "Generating new token for space",
        "Adding token to wallet",
      ],
      `Creating new Space for ${spaceData.spaceName}`
    );
    setErrorMsg("");
    if (requirePassword) {
      setOpenDialog(true);
      hideLoading();
      return;
    }

    console.log("got here");

    const validationMsg = validateCreateSpace(spaceData);
    if (validationMsg) {
      setErrorMsg(validationMsg);
      hideLoading();
      return;
    }
    console.log("got here");
    console.log("got here 2");

    const requiredBalance = "0.001";
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );
    if (txnValidationMsg) {
      setErrorMsg(txnValidationMsg);
      hideLoading();
      return;
    }
    try {
      const result = await createToken({ signer, spaceData });

      if (result.error) {
        console.error("Error in createToken:", result.error);
        setErrorMsg(result.error.message);
        hideLoading();
        return;
      }

      const mergedState = {
        ...spaceData,
        ...result,
      };

      const roomId = await handleCreateRoom({
        ...mergedState,
        tokenNewTokenizedSpace: true,
      });

      hideLoading();
      setSpaceId(roomId);
      setOpenSpaceDialog(false);
    } catch (error) {
      console.error("Error in createSpace function:", error);
      setErrorMsg("An error occurred: " + error.message);
      hideLoading();
    }
  };

  useEffect(() => {
    handleSocialClick("Github", "spaceSocialGithub");
  }, []);

  const handleSocialClick = (placeholder, field) => {
    setSpaceData((prevState) => ({ ...prevState }));
    setSelectedPlaceholder(placeholder);
    setCurrentField(field);
    setSelectedButton(placeholder);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files.item(0);
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      setErrorMsg(
        "Incorrect image format used, only PNG and JPEG images allowed"
      );
      return;
    }

    setSpaceData((prevState) => ({ ...prevState }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setSpaceData((prev) => ({ ...prev, spaceAvatarUrl: file }));
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleBannerUpload = (e) => {
    const file = e.target.files.item(0);
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      setErrorMsg(
        "Incorrect image format used, only PNG and JPEG images allowed"
      );
      return;
    }

    setSpaceData((prevState) => ({ ...prevState }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setSpaceData((prev) => ({ ...prev, spaceBannerUrl: file }));
      setBannerPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="create-space">
      <div className="create-proposal-content-wrapper">
        <div className="create-space-banner-logo-container">
          <div className="create-space-banner-container">
            {spaceData.spaceBannerUrl && (
              <img
                src={bannerPreview}
                alt="Uploaded banner preview"
                className="upload-space-logo-img"
              />
            )}
            {!spaceData.spaceBannerUrl && (
              <div className="spacetype-pages-button-upload-image">
                <span className="spacetype-pages-button-upload-text">
                  Space Banner
                </span>
              </div>
            )}
            <div className="create-space-upload-image-container">
              {spaceData.spaceAvatarUrl ? (
                <div className="remove-icon-container">
                  <img
                    src={imagePreview}
                    alt="Uploaded preview"
                    className="upload-space-logo-img"
                  />
                </div>
              ) : (
                <div className="upload-icon-container">
                  <GalleryEdit className="upload-space-logo-add" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="edit-media-wrapper" onClick={toggleModal}>
          <div className="edit-media-container">
            <span className="edit-media-container-text">Edit Media</span>
          </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={toggleModal}>
          <div className="create-space-media-modal-content">
            <input
              id="imageInput"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageUpload}
            />
            <div
              onClick={() => document.getElementById("imageInput").click()}
              className="create-space-media-button-upload"
            >
              <span className="create-space-media-button-text-upload">
                Upload Avatar
              </span>
            </div>
            <input
              id="bannerInput"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleBannerUpload}
            />
            <div
              onClick={() => document.getElementById("bannerInput").click()}
              className="create-space-media-button-upload"
            >
              <span className="create-space-media-button-text-upload">
                Upload Banner
              </span>
            </div>
            {spaceData.spaceAvatarUrl && (
              <div
                onClick={() => {
                  setSpaceData((prev) => ({ ...prev, spaceAvatarUrl: null }));
                  toggleModal();
                }}
                className="create-space-media-button"
              >
                <span className="create-space-media-button-text">
                  Remove Avatar
                </span>
              </div>
            )}
            {spaceData.spaceBannerUrl && (
              <div
                className="create-space-media-button"
                onClick={() => {
                  setSpaceData({ ...spaceData, spaceBannerUrl: "" });
                  toggleModal();
                }}
              >
                <span className="create-space-media-button-text">
                  Remove Banner
                </span>
              </div>
            )}
          </div>
        </Modal>
        <div className="create-room-space-details-dropdown-menu">
          <span className="create-space-secondary-text">Space name</span>
          <CustomInput
            placeholder="Enter space name"
            value={spaceData.spaceName}
            onChange={(e) => {
              setSpaceData((prevData) => ({
                ...prevData,
                spaceName: e.target.value,
              }));
            }}
            scrollToInput={scrollToInput}
          />
          <span className="create-space-secondary-text">Space Description</span>
          <CustomInput
            placeholder="Enter space description"
            value={spaceData.spaceDesc}
            resizable={true}
            maxHeight="106px"
            scrollToInput={scrollToInput}
            onChange={(e) => {
              setSpaceData((prevData) => ({
                ...prevData,
                spaceDesc: e.target.value,
              }));
            }}
          />
          <span className="create-space-secondary-text">Socials</span>
          <div className="icon-buttons-container">
            <div
              className={`icon-button ${
                selectedButton === "Github" ? "selected" : "unselected"
              }`}
              onClick={() => handleSocialClick("Github", "spaceSocialGithub")}
            >
              <Github className="arrow-svglogo-3" />
            </div>
            <div
              className={`icon-button ${
                selectedButton === "Twitter" ? "selected" : "unselected"
              }`}
              onClick={() => handleSocialClick("Twitter", "spaceSocialTwitter")}
            >
              <Twitter className="arrow-svglogo-3" />
            </div>
            <div
              className={`icon-button ${
                selectedButton === "Website" ? "selected" : "unselected"
              }`}
              onClick={() => handleSocialClick("Website", "spaceSocialWebsite")}
            >
              <Website className="arrow-svglogo-3" />
            </div>
          </div>
          {currentField && (
            <CustomInput
              placeholder={selectedPlaceholder}
              value={spaceData[currentField]}
              onChange={handleInputChange(currentField)}
              scrollToInput={scrollToInput}
            />
          )}
        </div>
        <div className="create-room-space-details-dropdown-menu">
          <span className="create-space-secondary-text">Token name</span>
          <CustomInput
            placeholder="Enter token name"
            value={spaceData.tokenName}
            onChange={(e) => {
              setSpaceData((prevData) => ({
                ...prevData,
                tokenName: e.target.value,
              }));
            }}
            scrollToInput={scrollToInput}
          />
          <span className="create-space-secondary-text">Token Symbol</span>
          <CustomInput
            placeholder="Token Symbol"
            textTransform={true}
            value={spaceData.tokenSymbol}
            onChange={(e) => {
              setSpaceData((prevData) => ({
                ...prevData,
                tokenSymbol: e.target.value,
              }));
            }}
            scrollToInput={scrollToInput}
          />
          <span className="create-space-secondary-text">
            Total Token Supply
          </span>
          <CustomInput
            placeholder="Total supply"
            value={spaceData.tokenTotalSupply}
            onChange={(e) => {
              setSpaceData((prevData) => ({
                ...prevData,
                tokenTotalSupply: e.target.value,
              }));
            }}
            scrollToInput={scrollToInput}
          />
        </div>
        <div className="spacetype-pages-error-message-container">
          {errorMsg && (
            <span className="tokenomics-table-error-message-text">
              {errorMsg}
            </span>
          )}
        </div>
        <div className="create-proposal-flex-container-button-wrapper">
          <div className="create-container-button-create" onClick={createSpace}>
            <span className="create-room-button-text">Create Space</span>
          </div>
        </div>
      </div>
      {openDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenDialog}
          headerText="Enter Password"
        >
          <Password onSubmit={handlePasswordInput} />
        </Popover>
      )}
    </div>
  );
};

export default CreateSpace;
