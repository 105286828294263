import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { executeTrade } from "../../../util/executeTrade";
import "./SwapReview.scss";
import { useLoading } from "../../contexts/LoadingContext";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import { ReactComponent as Eth } from "../../assets/svg/eth.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import {
  calculateGasFee,
  calculateOutputAmount,
} from "../../../util/calculateQuote";
import { ReactComponent as Warning } from "../../assets/svg/fillwarning.svg";
import { useEthersContext } from "../../contexts/EthersContext";
import Password from "../wallet-settings/Password";
import Popover from "../../atoms/popover/Popover";

const SwapReview = ({
  tokenIn,
  tokenInAddress,
  amountInExecutable,
  amountInUsd,
  amountIn,
  amountOut,
  tokenOut,
  tokenOutAddress,
  amountOutExecutable,
  amountOutUsd,
  gasFee,
  slippageTolerance,
  deadline,
  walletAddress,
  afterOptionSelect,
  avatarUrl,
  spaceId,
  poolFee,
  amountInUnformatted,
  provider,
}) => {
  const { showLoading, hideLoading } = useLoading();
  const [amountInToBeFulfilled, setAmountInToBeFulfilled] =
    useState(amountInExecutable);
  const [amountOutToBeFulfilled, setAmountOutToBeFulfilled] =
    useState(amountOutExecutable);
  const [amountInReview, setAmountInReview] = useState(amountIn);
  const [amountOutReview, setAmountOutReview] = useState(amountOut);
  const [amountInUsdReview, setAmountInUsdReview] = useState(amountInUsd);
  const [amountOutUsdReview, setAmountOutUsdReview] = useState(amountOutUsd);
  const [gasFeeReview, setGasFeeReview] = useState(gasFee);
  const [errorMessage, setErrorMessage] = useState("");
  const [priceUpdateNeeded, setPriceUpdateNeeded] = useState(false);
  const [outputAmounts, setOutputAmounts] = useState(null);
  const [outputGasFee, setOutputGasFee] = useState(null);

  function PriceUpdateComponent({
    adjustedAmountOut,
    amountOutExecutable,
    onConfirm,
    tokenOut,
  }) {
    const formattedAdjustedAmountOut = parseFloat(
      ethers.formatEther(adjustedAmountOut)
    );
    const formattedAmountOutExecutable = parseFloat(
      ethers.formatEther(amountOutExecutable)
    );

    const percentageChange =
      ((formattedAdjustedAmountOut - formattedAmountOutExecutable) /
        formattedAmountOutExecutable) *
      100;

    const formattedPercentageChange = percentageChange.toFixed(2);
    const formattedOutputAmount = formattedAdjustedAmountOut.toFixed(2);

    const changeString = `${
      percentageChange >= 0 ? "+" : ""
    }${formattedPercentageChange}%`;

    return (
      <div className="swap-review-price-update-container">
        <div className="swap-review-price-update-text-container">
          <span className="swap-review-details-wrapper-text">New Output</span>
          <span>
            <span className="swap-review-details-wrapper-subtext-output">
              {formattedOutputAmount} {tokenOut}
            </span>
            <span className="swap-review-details-wrapper-text-output">
              ({changeString})
            </span>
          </span>
        </div>
        <div onClick={onConfirm} className="swap-review-price-accept-container">
          <span className="swap-review-price-accept-text">Accept</span>
        </div>
      </div>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { gasFee } = await calculateGasFee(
          amountInUnformatted,
          18,
          provider,
          tokenInAddress,
          tokenOutAddress,
          poolFee
        );

        const outputAmounts = await calculateOutputAmount(
          amountInUnformatted,
          18,
          18,
          provider,
          tokenInAddress,
          tokenOutAddress,
          poolFee,
          tokenIn
        );
        setOutputAmounts(outputAmounts);
        setOutputGasFee(gasFee);

        if (outputAmounts.adjustedAmountOut !== amountOutToBeFulfilled) {
          setPriceUpdateNeeded(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 4000);
    return () => clearInterval(intervalId);
  }, [amountOutToBeFulfilled]);

  const { fetchSigner, handlePasswordSubmit } = useEthersContext();
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  const handlePasswordInput = (password) => {
    handlePasswordSubmit(password);
    setOpenPasswordDialog(false);
  };

  const handleConfirmSwap = async () => {
    const { signer, requirePassword } = await fetchSigner();
    setErrorMessage("");
    if (requirePassword) {
      setOpenPasswordDialog(true);
      hideLoading();
      return;
    }
    try {
      showLoading(
        [
          "Preparing your token swap",
          "Processing your token exchange",
          "Almost done with your token swap",
        ],
        "Executing token swap"
      );

      const result = await executeTrade(
        signer,
        tokenIn,
        tokenInAddress,
        tokenOutAddress,
        amountInToBeFulfilled,
        amountOutToBeFulfilled,
        slippageTolerance,
        deadline,
        walletAddress
      );

      if (result.success) {
        afterOptionSelect();
      } else {
        console.error("Trade execution failed:", result.error);
        setErrorMessage("Trade execution failed.");
      }
    } catch (error) {
      console.error(
        "An unexpected error occurred during the token swap:",
        error
      );
      setErrorMessage("An unexpected error occurred.");
    } finally {
      hideLoading();
    }
  };

  const handleClose = () => {
    afterOptionSelect();
  };

  const handleConfirm = async () => {
    if (outputAmounts) {
      try {
        const formattedAmount = Number(
          outputAmounts.readableAdjustedAmount
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        setAmountOutReview(formattedAmount);
        setAmountOutToBeFulfilled(outputAmounts.adjustedAmountOut);
        setAmountOutUsdReview(outputAmounts.outputValueUsd);
        setGasFeeReview(outputGasFee);
        setPriceUpdateNeeded(false);
      } catch (error) {
        console.error("Error handling confirm:", error);
      }
    }
  };

  return (
    <div className="swap-review-component">
      <div className="swap-review-text-container">
        <span className="swap-review-in-text">You're swapping</span>
      </div>
      <div className="swap-review-component-wrapper">
        <div className="swap-review-in-text-component">
          <span className="swap-review-in-amount-text ">
            {amountInReview} {tokenIn}
          </span>
          <span className="swap-review-in-amount-usd-text">
            ${amountInUsdReview}
          </span>
        </div>
        <div>
          {tokenIn === "ETH" ? (
            <Eth className="swap-review-component-svglogo" />
          ) : (
            <RoomAvatar
              roomId={spaceId}
              imageSrc={avatarUrl}
              size={52}
              borderRadius={99}
            />
          )}
        </div>
      </div>
      <div className="swap-review-arrow-down">
        <ArrowDown className="arrow-down-svglogo" />
      </div>
      <div className="swap-review-component-wrapper-2">
        <div className="swap-review-in-text-component">
          <span className="swap-review-in-amount-text ">
            {amountOutReview} {tokenOut}
          </span>
          <span className="swap-review-in-amount-usd-text">
            ${amountOutUsdReview}
          </span>
        </div>
        <div>
          {tokenIn !== "ETH" ? (
            <Eth className="swap-review-component-svglogo" />
          ) : (
            <RoomAvatar
              roomId={spaceId}
              imageSrc={avatarUrl}
              size={52}
              borderRadius={99}
            />
          )}
        </div>
      </div>
      {priceUpdateNeeded && (
        <PriceUpdateComponent
          adjustedAmountOut={outputAmounts.adjustedAmountOut}
          amountOutExecutable={amountOutToBeFulfilled}
          onConfirm={handleConfirm}
          tokenOut={tokenOut}
        />
      )}
      {errorMessage && (
        <div className="swap-review-error-container">
          <Warning className="warning-svglogo" />
          <span className="swap-component-warning-text">{errorMessage}</span>
        </div>
      )}
      <div className="swap-review-text-details-container">
        <span className="swap-review-in-text">Swap Details</span>
      </div>
      <div className="swap-review-details-wrapper">
        <span className="swap-review-details-wrapper-text">Max Slippage</span>
        <span className="swap-review-details-wrapper-subtext">
          {slippageTolerance}%
        </span>
      </div>
      <div className="swap-review-details-wrapper">
        <span className="swap-review-details-wrapper-text">Fee</span>
        <span className="swap-review-details-wrapper-subtext">
          {(poolFee / 10000).toFixed(1)}%
        </span>
      </div>
      <div className="swap-review-details-wrapper">
        <span className="swap-review-details-wrapper-text">Network cost</span>
        <span className="swap-review-details-wrapper-subtext">
          ${gasFeeReview}
        </span>
      </div>

      <div className="swap-review-actions-container">
        <div
          className="swap-rewiew-back-component-button"
          onClick={handleClose}
        >
          <ArrowDown className="arrow-return-svglogo" />
        </div>
        <div
          className={`swap-review-confirm ${
            priceUpdateNeeded ? "enabled" : ""
          }`}
          onClick={handleConfirmSwap}
        >
          <span
            className={`swap-review-confirm-text ${
              priceUpdateNeeded ? "enabled" : ""
            }`}
          >
            Confirm Swap
          </span>
        </div>
      </div>
      {openPasswordDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenPasswordDialog}
          headerText="Enter Password"
        >
          <Password onSubmit={handlePasswordInput} />
        </Popover>
      )}
    </div>
  );
};

export default SwapReview;
